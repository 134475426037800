import { computed } from 'vue';
import { useFeature } from '@tcgplayer/martech-components';
import { useUser } from '@/use/useUser';

export default function useLinksSameTab() {
  const { isOptimizelyFeatureEnabled } = useFeature();
  const { userKey } = useUser();

  const isOpenLinkSameTabEnabled = isOptimizelyFeatureEnabled('mt-mp-links-same-tab', { userKey });
  const featureFlagUrlTarget = computed(() => (isOpenLinkSameTabEnabled.value ? false : '_blank'));

  return {
    isOpenLinkSameTabEnabled,
    featureFlagUrlTarget,
  };
}
