import { getField, updateField } from 'vuex-map-fields';
import Api from '@/api/api';

function convertReach(promoReach) {
  let reach = promoReach;
  if (typeof reach === 'string') {
    reach = reach.split(',');
  }
  for (let i = 0; i < reach.length; i++) {
    const r = reach[i].toLowerCase();
    switch (r) {
      case 'magic':
        reach[i] = 'magic';
        break;
      case 'yugioh':
        reach[i] = 'yugioh';
        break;
      case 'pokemon':
        reach[i] = 'pokemon';
        break;
      default:
        reach[i] = r;
        break;
    }
  }
  return reach;
}

function populateLabels(params) {
  const labels = [];
  if (typeof params === 'string') {
    params = params.split(',');
  }
  for (let i = 0; i < params.length; i++) {
    labels.push(params[i]);
  }

  return labels;
}

function getFromAvailablePromos(resolve, reject, state, promoSize) {
  if (Array.isArray(state.availablePromo[promoSize])) {
    const promo = state.availablePromo[promoSize].shift();

    if (promo) {
      return resolve(promo);
    }
  }

  return reject();
}

function addToAvailablePromos(state, promoSize, results) {
  for (let i = 0; i < results.length; i++) {
    state.usedPromos.push(results[i].uuid);

    // The first promo is returned to the original requestor so skip it
    if (i === 0) continue; /* eslint-disable-line */

    if (!Array.isArray(state.availablePromo[promoSize])) {
      state.availablePromo[promoSize] = [];
    }

    state.availablePromo[promoSize].push(results[i]);
  }
}

function addToQueue(state, promoSize, resolve, reject) {
  const promo = { resolve, reject };

  if (!Array.isArray(state.queue[promoSize])) {
    state.queue[promoSize] = [];
  }

  state.queue[promoSize].push(promo);
}

function resolveQueue(state, promoSize) {
  if (!Array.isArray(state.queue[promoSize])) return;

  for (let i = 0; i < state.queue[promoSize].length; i++) {
    getFromAvailablePromos(state.queue[promoSize][i].resolve, state.queue[promoSize][i].reject, state, promoSize);
  }
}

function getPromos(state, contentType, promoReach, promoSize) {
  // check last promoReach, if different then clear used and available promos from previous feed.
  let lastPromoReach = '';

  if (lastPromoReach !== promoReach) {
    state.availablePromo = {};
    state.usedPromos = [];
    lastPromoReach = promoReach;
  }

  // Check for existance of promos of that size in store and if there promise.resolve and short circuit.
  if (Array.isArray(state.availablePromo[promoSize])) {
    const promo = state.availablePromo[promoSize].shift();

    if (promo) {
      return Promise.resolve(promo);
    }
  }

  // If we're already getting data for this promo size then add it to the queue to be resolved after the response
  if (state.loading[promoSize]) {
    return new Promise((resolve, reject) => {
      addToQueue(state, promoSize, resolve, reject);
    });
  }

  // Mark as loading for this promo size and get the promos from the service
  state.loading[promoSize] = true;
  // classification and labels are the query string parameters for the tags request
  const classifications = [];
  let labels = [];

  if (contentType) {
    classifications.push('contentType');
    labels = labels.concat(populateLabels(contentType));
  }

  if (promoReach) {
    classifications.push('reach');
    const reach = convertReach(promoReach);
    labels = labels.concat(populateLabels(reach));
  }

  if (promoSize) {
    classifications.push('size');
    labels = labels.concat(populateLabels(promoSize));
  }

  return Api.getTags({
    domains: 'content', classifications, labels,
  })
    .then((response) => {
      const results = response.data.result;
      const tags = {};

      for (let i = 0; i < results.length; i++) {
        if (!tags[results[i].classification]) {
          tags[results[i].classification] = [];
        }
        tags[results[i].classification].push(results[i].id);
      }

      return Api.getPromos({ tags });
    })
    .then((response) => {
      const results = response.data.result;

      addToAvailablePromos(state, promoSize, results); // Add results to the available promos queue for the requested promo size
      resolveQueue(state, promoSize); // Resolve all of the requested promo promises that are queued up

      state.loading[promoSize] = false;

      return results[0]; // Return the first result to the first request that came in that initiated the service call
    })
    .catch((error) => {
      resolveQueue(state, promoSize);
      state.loading[promoSize] = false;
      return Promise.reject(error);
    });
}

export default ({
  namespaced: true,
  state: {
    availablePromo: {},
    usedPromos: [],
    errors: {},
    loading: {},
    queue: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
  },
  actions: {
    loadPromos({ state }, {
      contentType,
      promoReach,
      promoSize,
    }) {
      return getPromos(state, contentType, promoReach, promoSize);
    },
  },
});
