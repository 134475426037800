<template>
  <on-enter-navigate-card :data="articleData" external-link>
    <div class="condensed" tabindex="-1" :data-testid="componentID()">
      <div class="condensed-labels">
        <exact-match-tag v-show="articleData.isExactMatch"/>
        <p class="martech-labels martech-blue">
          {{ verticalDisplay }}
        </p>
      </div>
      <div class="columns" @mouseleave="showCardHover = false">
        <div class="column">
          <div class="name" @click="magnify">
            <p class="card-name martech-body martech-barlow martech-text-capitalize martech-one-line">
              {{ displayName }}
            </p>
            <div class="magnify" @mouseenter="cardHoverActive">
              <iconography-magnify-icon/>
              <div v-if="deviceType === 'desktop'">
                <transition name="fade" mode="out-in">
                  <martech-card-spotlight
                    v-show="showCardHover"
                    :data="articleData.cardData"
                    :is-flipped="isFlipped"
                    class="spotlight shadow"
                    :class="showCardHover ? 'is-active' : 'is-hidden'"
                    :card-product-url="cardProductUrlUtm"
                    :card-id="articleData.tcgPlayerID"
                    hover
                    @amplitude-event="sendClickEvent"
                    @magnify="magnifyCardImage"
                    @flip="isFlipped = !isFlipped"/>
                </transition>
              </div>
            </div>
          </div>
          <div class="group set-group">
            <p class="martech-text-sm martech-two-lines">
              {{ setName }}
            </p>
            <p class="martech-text-sm martech-text-capitalize martech-one-line rarity">
              <span v-show="rarity">&nbsp;|&nbsp;</span>{{ rarity }}
            </p>
          </div>
        </div>
        <div class="column">
          <div class="group">
            <p class="martech-text-sm martech-text-capitalize martech-one-line">
              {{ cardType }}
            </p>
          </div>
          <div class="group">
            <div class="price">
              <market-price-template
                align-text="left"
                class="card-card--price"
                :price="marketPrice"/>
            </div>
            <base-button
              v-if="deviceType === 'mobile' && canonicalURL"
              :link="{linkUrl: cardProductUrlUtm, linkText: 'Buy on TCGplayer', urlTarget: featureFlagUrlTarget}"
              btn-style="martech-black-outline"
              btn-size="martech-small"
              class="buy-button"/>
          </div>
        </div>
        <div v-if="deviceType === 'desktop' && canonicalURL" class="column">
          <base-button :link="{linkUrl: cardProductUrlUtm, linkText: 'Buy on TCGplayer', urlTarget: featureFlagUrlTarget}" btn-style="martech-black-outline" btn-size="martech-small" class="buy-button"/>
        </div>
      </div>
    </div>
    <desktop-magnify-image
      v-if="openModal"
      :card-product-url="cardProductUrlUtm"
      :card-id="articleData.tcgPlayerID"
      :card-data="articleData.cardData"
      :analytics-data="analyticsData"
      @close="closeModal"/>
    <mobile-product-details-sheet
      :card-data="articleData.cardData"
      :loaded="render"
      :card-id="articleData.tcgPlayerID"
      :analytics-data="analyticsData"
      :card-product-url="cardProductUrlUtm"
      :show="openModal"
      @close="closeModal"/>
  </on-enter-navigate-card>
</template>

<script>
import {
  BaseButton,
  MartechCardSpotlight,
  IconographyMagnifyIcon,
  MarketPriceTemplate,
  VerticalHelpers as verts
} from '@tcgplayer/martech-components';
import deviceType from '@/mixins/deviceType';
import DesktopMagnifyImage from '@/components/product-details/DesktopMagnifyImage.vue';
import MobileProductDetailsSheet from '@/components/product-details/MobileProductDetailsSheet.vue';
import ExactMatchTag from '@/components/card-elements/ExactMatchTag.vue';
import truncate from '@/mixins/filters/truncate';
import product from '@/components/card-elements/Cards/mixins/product';
import OnEnterNavigateCard from '@/components/card-elements/OnEnterNavigateCard.vue';
import url from '@/lib/url';
import useLinksSameTab from '@/use/featureFlags/useLinksSameTab';

export default {
  name: 'condensed-product',
  components: {
    MarketPriceTemplate,
    IconographyMagnifyIcon,
    DesktopMagnifyImage,
    MobileProductDetailsSheet,
    ExactMatchTag,
    OnEnterNavigateCard,
    BaseButton,
    MartechCardSpotlight,
  },
  mixins: [ deviceType, truncate, product ],
  props: {
    articleData: {
      type: Object,
      required: true,
      default: null,
    },
    analyticsData: {
      type: Object,
      required: false,
      // eslint-disable-next-line
      default: () => { return { analyticsCampaign: 'search', analyticsSource: 'infinite', analyticsMedium: 'product-card'}; }
    },
    image: {
      type: String,
      required: true,
      default: '',
    },
    showVerticalLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: false,
      default: (article) => {},
    },
  },
  setup() {
    const { isOpenLinkSameTabEnabled, featureFlagUrlTarget } = useLinksSameTab();

    return {
      isOpenLinkSameTabEnabled,
      featureFlagUrlTarget,
    };
  },
  data() {
    return {
      render: false,
      vertical: '',
      openModal: false,
      showCardHover: false,
    };
  },
  computed: {
    verticalDisplay() {
      return verts.displayName(this.vertical);
    },
    cardProductUrlUtm() {
      return url.urlUtm(this.analyticsData, this.canonicalURL);
    },
  },
  methods: {
    magnify() {
      if (this.deviceType === 'mobile') {
        this.openModal = true;
      }
    },
    magnifyCardImage() {
      this.openModal = !this.openModal;
    },
    closeModal() {
      this.openModal = false;
    },
    cardHoverActive() {
      if (this.deviceType === 'desktop') {
        this.showCardHover = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.name {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $martech-gray-160;
  position: relative;

  svg {
    margin-left: $martech-spacer-2;
  }
}

.magnify {
  position: relative;
}

.spotlight {
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 50;
}

  .card-name {
    margin-bottom: 0;
    color: $martech-text-primary;
    font-weight: $martech-weight-bold;
  }

.condensed {
  padding: $martech-spacer-2 $martech-spacer-3;
  border-bottom: 1px solid $martech-border;

  @include breakpoint(768) {
    flex-direction: column;
    padding: 0.75rem $martech-spacer-3;
  }

  @media only screen and (min-width: 1410px) {
    flex-direction: row;
  }

  &-labels {
    display: flex;
    align-items: center;
    padding-bottom: $martech-spacer-1;

    :deep(.exact-match) {
      margin-right: $martech-spacer-2;
    }
  }

  .group {
    width: 100%;
    display: flex;
    flex-direction: column;

    .buy-button {
      :deep(a) {
        width: 100%;
      }
    }

    @include breakpoint(768) {
      align-items: center;
      flex-direction: row;
    }
  }

  .columns {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(1200) {
      flex-direction: row;
    }

    .column {
      width: 100%;
      min-height: 42px;

      .group {
        &:nth-child(1) {
          padding: 0 0 $martech-spacer-2 0;

          @media only screen and (min-width: 1025px) {
            padding: 0;
          }
        }
      }

      @include breakpoint(1200) {
        &:nth-child(1) {
          width: 40%;
        }

        &:nth-child(2) {
          border-left: 1px solid $martech-border;
          padding-left: $martech-spacer-3;
          width: 40%;
        }

        &:nth-child(3) {
          width: 25%;
        }
      }

      @include breakpoint(1500) {
        &:nth-child(1) {
          width: 60%;
        }

        &:nth-child(3) {
          width: 20%;
        }
      }
    }
  }

  .set-group {
    flex-direction: row;

    p {
      color: $martech-text-subdued;
      font-weight: $martech-weight-semibold;
    }

    .rarity {
      font-weight: $martech-weight-normal;
    }
  }

  .price {
    padding-bottom: $martech-spacer-2;

    @include breakpoint(768) {
      padding-bottom: 0;
    }
  }

  .secondary-outline {
    height: 32px;
    font-size: $martech-type-14;

    @media only screen and (min-width: 1025px) {
      margin-top: $martech-spacer-2;
    }

    @include breakpoint(1200) {
      margin-top: 0;
    }
  }
}

:deep .market-price { /* eslint-disable-line */
  margin-right: $martech-spacer-3;

  &__title,
  &__price {
    p {
      font-size: $martech-type-12;
      line-height: 16px;
      letter-spacing: 0.4px;

      @include breakpoint(768) {
        font-size: $martech-type-14;
        line-height: 24px;
      }
    }
  }

  .secondary-outline {
    height: 32px;
    font-size: $martech-type-12;

    @include breakpoint(768) {
      font-size: $martech-type-14;
    }
  }
}
</style>
