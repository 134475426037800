<template>
  <div
    ref="subNav"
    class="sub-navigation"
    :class="{
      'more-games' : verticalType === 'more games',
      'wide-content': siteConfig('verticalLanding.wideContent'),
    }"
    :data-testid="componentID()">
    <div class="container" :class="{'mobile-scroll' : isMobile}">
      <div ref="wrapper" class="wrapper">
        <h1 v-if="verticalName" ref="heading" class="martech-semi-condensed martech-text-uppercase">
          {{ verticalName }}
        </h1>
        <ul v-if="showTabLinks" ref="navItems" class="items" :class="{'mobile-scroll' : isMobile || isTablet}">
          <template v-for="(item, index) in menu">
            <li v-if="displaySubNavItems(item)" :key="index" ref="item">
              <router-link
                :to="getVerticalLandingPath(item.route)"
                class="item-link martech-text-md martech-text-capitalize"
                :class="[
                  item.title.replace(' ', '-'),
                  {
                    'is-active': activeTab(item.route),
                    'orange-brand': siteConfig('global.orangeBrand'),
                  }]"
                @click="linkClick(getVerticalLandingPath(item.route), item.title)">
                <span>
                  <div v-if="item.title === 'pro'">
                    <img :src="item.icon" :alt="activeTab(item.route)" class="pro-icon">
                  </div>
                  <div
                    v-else
                    :class="activeTab(item.route)
                      ? `martech-icon ${item.icon} ${siteConfig('global.orangeBrand') ? 'martech-black' : 'martech-blue'} martech-x-small`
                      : `martech-icon ${item.icon} martech-black martech-x-small`"/>
                  {{ item.title }}
                </span>
              </router-link>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import amplitudeEvent from '@tcgplayer/amplitude';
import useSiteConfig from '@/use/useSiteConfig';
import { useUser } from '@/use/useUser';
import { VerticalHelpers as verts, useDeviceType, useFeature } from '@tcgplayer/martech-components';

const props = defineProps({
  verticalType: {
    type: String,
    required: true,
    default: 'magic',
  },
  vertical: {
    type: String,
    required: true,
    default: 'magic',
  },
});

const route = useRoute();
const { isMobile, isTablet } = useDeviceType();
const { siteConfig } = useSiteConfig();
const { isOptimizelyFeatureEnabled } = useFeature();
const { userKey } = useUser();
const isCFBMigrationEnabled = isOptimizelyFeatureEnabled('mt-cfb-migration', { userKey });

const menu = computed(() => [
  {
    title: 'content home',
    route: '/',
    icon: 'martech-home-icon',
    hide: [],
    domain: [ 'infinite', 'channel fireball', 'content' ],
  },
  {
    title: 'articles',
    route: '/articles',
    icon: 'martech-articles-icon',
    hide: [],
    domain: [ 'infinite', 'channel fireball', 'content' ],
  },
  {
    title: 'commander bestiary',
    route: '/commander-bestiary',
    icon: 'martech-bestiary-icon',
    hide: siteConfig('verticalLanding.showBestiary'),
    domain: [ 'infinite', 'content' ],
  },
  {
    title: 'ChannelFireball',
    route: '/channelfireball',
    icon: 'martech-cfb-outline-solid-c-icon',
    hide: isCFBMigrationEnabled.value ? [ 'yugioh', 'pokemon', 'lorcana', 'flesh and blood', ] : [ 'magic', 'yugioh', 'pokemon', 'lorcana', 'flesh and blood', ],
    domain: [ 'infinite', 'content' ],
  },
  {
    title: 'decks & events',
    route: '/decks',
    icon: 'martech-deck-icon',
    hide: [ 'pokemon', 'flesh and blood', ],
    domain: [ 'infinite', 'channel fireball', 'content' ],
  },
  {
    title: 'deck search',
    route: '/decks/advanced-search',
    icon: 'martech-deck-search-icon',
    hide: [ 'pokemon', 'lorcana', 'flesh and blood', ],
    domain: [ 'infinite', 'channel fireball', 'content' ],
  },
]);

const verticalName = computed(() => verts.displayName(props.vertical));

const showTabLinks = computed(() => ![ 'more games', 'TCGplayer topics', 'TCGplayer series' ].includes(props.verticalType));

const displaySubNavItems = (item) => {
  if (item.title === 'commander bestiary') {
    return item.hide.includes(props.verticalType.toLowerCase()) && item.domain.includes(siteConfig('global.domain'));
  }

  return !item.hide.includes(props.verticalType.toLowerCase()) && item.domain.includes(siteConfig('global.domain'));
};

const getVerticalLandingPath = (path) => {
  const link = verts.verticalLink(props.verticalType);
  return `${link}${path}`;
};

const activeTab = (r) => {
  const currentPath = route.path.replace(verts.verticalLink(props.verticalType), '');
  return r === currentPath || r === `${currentPath}/`;
};

const linkClick = (linkURL, linkTitle) => {
  amplitudeEvent('infinite', 'infiniteNavigationClicked', {
    linkURL,
    linkTitle,
    location: 'subnav',
  });
};
</script>

<style lang="scss" scoped>
.sub-navigation {
  background-color: $martech-gray-0;
  border-bottom: 1px solid $martech-border;
  padding: $martech-spacer-3 0 0;
  width: 100%;
  height: 78px;
  display: flex;
  align-items: center;

  &.more-games {
    height: 55px;
  }

  @include breakpoint(1024) {
    padding: 0;
  }

  @include breakpoint(1500) {
    height: 55px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    transition: all 300ms cubic-bezier(0.25, 0.1, 0.25, 1);

    @include breakpoint(1500) {
      align-items: center;
      flex-direction: row;
    }
  }

  h1 {
    font-size: $martech-type-18;
    line-height: 1.125rem;
    letter-spacing: 0.64px;
    color: $martech-black;
    font-weight: $martech-weight-bold;
    margin-bottom: $martech-spacer-3;
    margin-top: 0;

    @include breakpoint(1024) {
      font-size: $martech-type-20;
      line-height: 2.5rem;
      letter-spacing: 1.43px;
      margin-bottom: 0;
      margin-right: $martech-spacer-3;
    }
  }

  ul {
    display: inline-flex;
    list-style: none;
    padding: 0;

    &.mobile-scroll {
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    scrollbar-width: none;
    height: 32px;

    &::-webkit-scrollbar {
      display: none;
    }

      .item-link {
        scroll-snap-align: start;
        padding: 0 0 $martech-spacer-2 0;
        height: 28px;
        color: $martech-text-primary;

        span {
          display: flex;
          align-items: center;
          color: $martech-text-primary;
        }

        &.is-active {
          height: 31px;
        }

        &.home{
          width: 97px;
        }

        &.articles {
          width: 103px;
        }

        &.decks {
          width: 150px;
        }

        &.search {
          width: 140px;
        }
      }
  }

    @include breakpoint(1500) {
      justify-content: center;
      padding-left: $martech-spacer-4;
      width: auto;
    }

    li {
      letter-spacing: 0.18px;
      display: flex;
      margin-right: $martech-spacer-4;

      &:last-of-type {
        margin-right: 0;
      }

      @include breakpoint(1024) {
        height: 40px;
        margin-right: 0;
      }

      @include breakpoint(1500) {
        height: 55px;
        align-items: flex-end;
        width: auto;
      }

      .item-link {
        display: flex;
        align-items: center;
        font-weight: $martech-weight-medium;
        padding: 0 0 $martech-spacer-2 0;
        justify-content: center;
        color: $martech-text-primary;

        @include breakpoint(768) {
           padding: 0 0 $martech-spacer-2 0;
        }

        @include breakpoint(1024) {
          padding: 0 $martech-spacer-3 3px $martech-spacer-3;
          height: 40px;

          &.content-home {
            padding: 0 $martech-spacer-4 3px 0;
          }
        }

        @include breakpoint(1500) {
          padding: 0 $martech-spacer-4 3px $martech-spacer-4;

          height: 53px;
        }

        &.content-home {
          min-width: 128px;

          @include breakpoint(1500) {
            width: auto;
          }
        }

        &.commander-bestiary {
          min-width: 175px;

          @include breakpoint(1500) {
            width: auto;
          }
        }

        &.events {
          min-width: 145px;

          @include breakpoint(1500) {
            width: auto;
          }
        }

        &.deck-search {
          min-width: 115px;

          @include breakpoint(1500) {
            width: auto;
          }
        }

        span {
          display: flex;
          align-items: center;
        }

        &.is-active {
          color: $martech-blue;
          border-bottom: 3px solid $martech-blue;

          &.orange-brand {
            color: $martech-cfb-orange-dark;
            border-bottom: 3px solid $martech-cfb-orange-base;
          }

          @include breakpoint(1024) {
            height: 40px;
          }

          @include breakpoint(1500) {
            height: 50px;
          }
        }

        &.home,
        &.decks,
        &.articles,
        &.search {
          @include breakpoint(768) {
            width: 100%;
          }
        }
      }

      .martech-icon {
        margin-right: $martech-spacer-2;
      }

      .pro-icon {
        width: 25px;
        margin-right: $martech-spacer-2;
      }
    }
  }
}
</style>
