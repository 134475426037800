<template>
  <base-styles>
    <div class="card-spotlight-price-history" :data-testid="componentID()" :style="{ width: width }">
      <div class="card-spotlight-price-history__base">
        <div class="group">
          <div class="card-details">
            <h2>
              {{ data.name || data.productName }} <flip-icon v-if="showFlipButton" role="button" class="flip-icon"
                @click="emit('flip')" />
            </h2>
            <p class="set-rarity">{{ data.setName || data.set }}<span v-if="data.rarity" class="martech-text-capitalize">,
                {{ data.rarity }}</span></p>
          </div>
          <base-button v-if="productURL && deviceType === 'desktop'"
            btn-style="martech-black"
            btn-size="martech-medium"
            class="buy-button"
            :icon-classes="iconClasses"
            :link="{ linkText: 'Buy on TCGplayer', linkUrl: productURL, urlTarget: featureFlagUrlTarget }"
            @clicked="emit('amplitude-event')" />
        </div>
      </div>
      <div class="card-spotlight-price-history__columns">
        <div class="card-spotlight-price-history__column">
          <template v-if="$slots['slotted-image']">
            <slot name="slotted-image" />
          </template>
          <template v-else>
            <image-card
                v-if="isProduct"
                :loaded="loaded"
                :card-data="data"
                :product-image-url="productImageUrl"
                :product-image-alt-text="productImageAltText"
                :is-product="true"
                :card-dimensions="{width: imageDimensions.width, height: 'auto'}"
                @flip="emit('flip')"/>
            <image-card
                v-else
                :loaded="loaded"
                :card-data="data"
                :product-image-url="productImageUrl"
                :product-image-alt-text="productImageAltText"
                :flip-is-icon="flipIsIcon"
                :allow-flip="false"
                :card-dimensions="{width: imageDimensions.width, height: imageDimensions.height}"
                :is-flipped="isFlipped"
                @flip="emit('flip')"/>
          </template>
        </div>
        <div class="card-spotlight-price-history__column">
          <div class="product-details">
            <div class="card-spotlight-price-history__graph">
              <price-history 
                :disable-settings="disablePriceHistorySettings" 
                :disable-timeframe="disablePriceHistoryTimeframe"
                :product-id="getCardId" 
                :condition="condition"
                :language="language"
                :printing="printing"
                :display-volume="false"
                display-card-title
                :card-name="data.name"
                :set-name="data.setName"
                :allow-skip-days="false" />
              <price-points :price-points="data.pricePoints" v-if="pricePoints" />
              <div class="price" v-else>
                <market-price-template :price="data.marketPrice" align-text="left" />
              </div>
            </div>
            <base-button v-if="productURL && deviceType === 'mobile'"
              btn-style="martech-black"
              btn-size="martech-medium"
              class="buy-button" 
              :class="{ 'mobile-btn': deviceType === 'mobile' }"
              :icon-classes="iconClasses"
              :link="{ linkText: 'Buy on TCGplayer', linkUrl: productURL, urlTarget: featureFlagUrlTarget }"
              @clicked="emit('amplitude-event')" />
          </div>
        </div>
      </div>
    </div>
  </base-styles>
</template>

<script setup>
import { computed, defineEmits } from 'vue';
import BaseStyles from '@/components/BaseStyles.vue';
import BaseButton from '@/components/elements/BaseButton.vue';
import PriceHistory from '@/components/price-history/History.vue';
import ImageCard from '@/components/image/Card.vue';
import FlipIcon from '@/components/iconography/FlipIcon.vue';
import useDeviceType from '@/use/deviceType';
import PricePoints from '@/components/product-details/parts/PricePoints.vue';
import MarketPriceTemplate from '@/components/MarketPriceTemplate.vue';

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: () => ({
      name: '',
      flipImageURL: '',
      imageURL: '',
      layout: '',
      marketPrice: '',
      pricePoints: {
        Normal: {},
        Foil: {},
      },
      rarity: '',
      setName: '',
      description: '',
      productUrl: '',
      tcgPlayerID: '',
      typeLine: '',
      convertedManaCost: '',
      manaCost: '',
      cardType: '',
    }),
  },
  productImageUrl: {
    type: String,
    required: false,
    default: false,
  },
  productImageAltText: {
    type: String,
    required: false,
    default: false,
  },
  isProduct: {
    type: Boolean,
    required: false,
    default: false,
  },
  loaded: {
    type: Boolean,
    required: false,
    default: false,
  },
  isFlipped: {
    type: Boolean,
    required: false,
    default: false,
  },
  flipIsIcon: {
    type: Boolean,
    required: false,
    default: true,
  },
  cardProductUrl: {
    type: String,
    default: '',
    required: false,
  },
  cardId: {
    type: [Number, String,],
    default: '',
    required: false,
  },
  width: {
    type: String,
    required: false,
    default: '100%',
    notes: 'allows you to set the width of the card in percents or pixels.'
  },
  imageDimensions: {
    type: Object,
    required: false,
    default: () => ({
      width: '314px',
      height: '439px',
    }),
  },
  pricePoints: {
    type: Boolean,
    default: false,
  },
  disablePriceHistorySettings: {
    type: Boolean,
    default: false,
  },
  disablePriceHistoryTimeframe: {
    type: Boolean,
    default: false,
  },
  printing: {
    type: Array,
    default: null,
  },
  condition: {
    type: Array,
    default: null,
  },
  language: {
    type: Array,
    default: null,
  },
  featureFlag: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const { deviceType } = useDeviceType();
const emit = defineEmits(['flip', 'amplitude-event']);

const featureFlagUrlTarget = computed(() => props.featureFlag ? '' : '_blank');

const iconClasses = computed(() => props.featureFlag ? '' : 'martech-icon martech-right martech-external-link-icon martech-small martech-white');

const getCardId = computed(() => {
  return props.data?.tcgPlayerID || props.cardId;
})

const showFlipButton = computed(() => {
  return props.data?.layout.match(/transform|modal_dfc/) || '';
})

const productURL = computed(() => {
  return props.cardProductUrl || props.data?.productUrl || props.data?.tcgProductUrl || '';
})
</script>
  
<style lang="scss" scoped>
.card-spotlight-price-history {
  border-radius: $martech-radius-medium;
  border: 1px solid $martech-border;
  padding: $martech-spacer-3;
  font-family: $martech-display-inter;
  display: block;
  margin: auto;
  text-decoration: none;
  color: $martech-text-primary;
  background: $martech-surface;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.20);

  @include breakpoint(1024) {
    padding: $martech-spacer-4;
  }

  @include breakpoint(1200) {
    max-width: 825px;
  }

  &__base {
    margin-bottom: $martech-spacer-3;

    .group {
      display: flex;
      justify-content: space-between;
    }

    h2 {
      font-family: $martech-display-inter;
      font-size: $martech-type-20;
      font-weight: $martech-weight-bold;
      line-height: $martech-type-24;

      @include breakpoint(1024) {
        font-size: $martech-type-22;
        line-height: 1.75rem;
      }
    }

    .flip-icon {
      margin-left: $martech-spacer-2;
      color: $martech-text-subdued;
      cursor: pointer;
    }

    .set-rarity {
      color: $martech-text-subdued;
      font-size: $martech-type-13;
      font-weight: 400;
    }
  }

  &__columns {
    display: flex;
    flex-direction: column;

    @include breakpoint(1024) {
      flex-direction: row;
    }
  }

  &__column {
    &:first-of-type {
      margin-bottom: $martech-spacer-5;
      display: flex;
      justify-content: center;

      @include breakpoint(1024) {
        margin-bottom: 0;
        margin-right: $martech-spacer-5;
      }
    }

    @include breakpoint(1024) {
      width: 50%;
    }
  }

  :deep(.price-points-table) {
    max-width: 440px;
    padding: 1.5rem 0 0;
  }

  &__graph {
    width: 100%;
  }

  .price {
    margin-top: $martech-spacer-4;
  }

  :deep(.martech-button) {
    @include breakpoint(1024) {
      width: 212px;
    }
  }

  .mobile-btn {
    :deep(.martech-button) {
      margin-top: $martech-spacer-3;
      width: 100%;
    }
  }
}
</style>  `
