import { ref } from 'vue';
import type { Ref } from 'vue';
import { get, set } from '@vueuse/core';
import Api from '@/api/api';

interface TopicsItem {
    label?: any;
    text: string,
    url: string,
}

export default function useGetTopics(filter: string) {
  const topics: Ref<TopicsItem[]> = ref([]);
  const topicsLoading = ref(true);

  const params = {
    domains: 'content',
    classifications: 'topic',
  };

  try {
    Api.getTags({ ...params, labels: undefined }).then((res) => {
      let t = res?.data?.result.map((item: { label: any; }) => Object.assign(item, { text: item.label, url: `/topics/${item.label}` })) || [];

      // Filter out specific topic
      t = t.filter((l: { label: string; }) => l.label !== filter?.toLowerCase());

      // Sort labels a-z
      t.sort((a: { label: string; }, b: { label: any; }) => a.label.localeCompare(b.label));

      // Adds all topics link to the front of the menu
      t.splice(0, 0, { text: 'all topics', url: '/topics' });

      set(topics, t);
      set(topicsLoading, false);
    });
  } catch (error) {
    set(topicsLoading, false);
  }

  return { topics, topicsLoading };
}
