<template>
  <base-styles>
    <div :class="data.name">
      <div v-if="subDecks" :class="[{ 'subdeck-header': subDecksHeader }, { 'special-header': specialCardHeader }]">
        <p class="section-title martech-text-capitalize" @click="toggleSection(title)">
        <div class="title">
          {{ title }} ({{ quantity }})
        </div>
        </p>
      </div>
      <transition-group name="slide-fade" mode="out-in">
        <!--sideboard-->
        <div v-if="!typed">
          <ul class="list">
            <li v-for="card in data.cards" id="list-item" :key="card.name" class="list__item"
              @mouseenter="setActiveCard(card)" @click.stop="setActiveModal">
              <span class="list__item--wrapper">
                <span class="list__item-quanity">
                  {{ card.quantity }}
                </span>
                <span v-if="isMobile" class="martech-hyphens martech-two-lines">{{ card.displayName }} <companion-paw
                    v-if="companionId && companionId === card.cardID" /></span>
                <base-link v-else :link-url="activeCardUrl" :url-target="featureFlagUrlTarget" @click="$emit('card-buy-click-event')">
                  <span class="martech-hyphens martech-two-lines">{{ card.displayName }} <companion-paw
                      v-if="companionId && companionId === card.cardID" /></span>
                </base-link>
              </span>
              <div v-if="card.ink" class="lorcana-ink">
                <Lorcana-symbols :cost="card.cost" :color="card.ink" :size="isMobile ? 18 : 22" />
                <Lorcana-symbols :cost="card.cost" :color="lorcanaInklessOrInkwell(card)" :size="isMobile ? 22 : 25" />
              </div>
              <div v-if="card.manaCost" class="mana">
                <mana-symbols :color="card.manaCost" :size="isMobile ? 12 : 14" :max-length="isMobile ? 5 : 10" />
              </div>
              <div class="price">
                <market-price-template :has-title="false" new-style :price="card.marketPrice" />
              </div>
            </li>
          </ul>
        </div>
        <!--maindeck groups-->
        <div v-for="[typeName, types] of cards" v-else-if="typed" :key="typeName">
          <div v-if="types.length" class="types">
            <p class="section-title martech-text-capitalize" @click="toggleSection(typeName)">
            <div class="title">
              {{ getTypeName(typeName) }} ({{ cardCount(types) }})
            </div>
            </p>
          </div>
          <transition name="slide-fade">
            <ul v-if="types.length" class="list">
              <li v-for="card in types" id="list-item" :key="card.name" class="list__item"
                @mouseenter="setActiveCard(card)" @click.stop="setActiveModal">
                <span class="list__item--wrapper">
                  <span class="list__item-quanity">
                    {{ card.quantity }}
                  </span>
                  <template v-if="isMobile">{{ card.displayName }} <div class="pkm-set-tag martech-labels" v-if="data.game === 'pokemon'">{{ card.set }}</div></template>
                  <base-link v-else :link-url="activeCardUrl" :url-target="featureFlagUrlTarget"
                    @click="$emit('card-buy-click-event')">
                    {{ card.displayName }} <div class="pkm-set-tag martech-labels" v-if="data.game === 'pokemon'">{{ card.set }}</div>
                  </base-link>
                </span>
                <div v-if="card.pitch" class="pitch">
                  <fab-card-pitch-icon :pitch="card.pitch" />
                </div>
                <div v-if="card.ink" class="lorcana-ink">
                  <Lorcana-symbols :cost="card.cost" :color="card.ink" :size="isMobile ? 18 : 22" />
                  <Lorcana-symbols :cost="card.cost" :color="lorcanaInklessOrInkwell(card)"
                    :size="isMobile ? 22 : 25" />
                </div>
                <div v-if="card.manaCost" class="mana">
                  <mana-symbols v-if="card.manaCost" :color="card.manaCost" :size="isMobile ? 12 : 14"
                    :max-length="isMobile ? 5 : 10" />
                </div>
                <div class="price">
                  <market-price-template :has-title="false" new-style :price="card.marketPrice" />
                </div>
              </li>
            </ul>
          </transition>
        </div>
      </transition-group>
    </div>
  </base-styles>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import useDeviceType from '@/use/deviceType';

import BaseStyles from '@/components/BaseStyles.vue';
import BaseLink from '@/components/elements/BaseLink.vue';
import CompanionPaw from '@/components/iconography/CompanionPawIcon.vue';
import MarketPriceTemplate from '@/components/MarketPriceTemplate.vue';
import ManaSymbols from '@/components/iconography/ManaSymbols.vue';
import LorcanaSymbols from '@/components/iconography/LorcanaInkColorCost.vue'
import deckEmbed from '@/lib/deckEmbed';
import Chevron from '@/components/iconography/Chevron.vue';
import FabCardPitchIcon from '@/components/iconography/FabCardPitchIcon.vue';

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  companionId: {
    type: [Number, String],
    required: false,
    default: 0,
  },
  typed: {
    type: Boolean,
    required: false,
    default: false,
  },
  setActiveCardCallback: {
    type: Function,
    required: false,
    default: () => { },
  },
  setActiveModal: {
    type: Function,
    required: false,
    default: () => { },
  },
  activeCardUrl: {
    type: String,
    required: false,
    default: '',
  },
  sort: {
    type: String,
    required: false,
    default: 'overview',
  },
  abbreviationManaColorToName: {
    type: Function,
    required: false,
    default: (c) => {
      if (!c) {
        return null;
      }

      switch (c.toLowerCase()) {
        case 'b':
          return 'Black';
        case 'g':
          return 'Green';
        case 'r':
          return 'Red';
        case 'u':
          return 'Blue';
        case 'w':
          return 'White';
        default:
          return 'Colorless';
      }
    },
  },
  featureFlag: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const activeCard = ref(null);
const cards = ref(new Map());

const { isMobile } = useDeviceType();

const subDecks = computed(() => ['sideboard', 'sidedeck', 'extradeck', 'commandzone', 'skillcard'].includes(props.data.name));
const subDecksHeader = computed(() => ['sideboard', 'sidedeck', 'extradeck'].includes(props.data.name));
const specialCardHeader = computed(() => ['commandzone', 'skillcard'].includes(props.data.name));
const quantity = computed(() => Object.values(props.data.cards).reduce((sum, card) => sum + card.quantity, 0));
const featureFlagUrlTarget = computed(() => props.featureFlag ? '' : '_blank');

const title = computed(() => {
  switch (props.data.name) {
    case 'skillcard':
      return 'Skill Card';
    case 'maindeck':
      return 'Main Deck';
    case 'sideboard':
      return 'Sideboard';
    case 'commandzone':
      return 'Commander';
    case 'sidedeck':
      return 'Side Deck';
    case 'extradeck':
      return 'Extra Deck';
    default:
      return '';
  }
});

const lorcanaInklessOrInkwell = (color) => (color.inkwell ? 'Inkwell' : 'Inkless');

const getSortType = (card) => {
  switch (props.sort) {
    case 'rarity':
      return card.rarity && card.rarity.toLowerCase();
    case 'quantity':
      return card.quantity;
    case 'color':
      if (!card.colors) {
        if (card?.convertedManaCost > 0) {
          return 'Colorless';
        }
        return 'None';
      }
      if (card.colors.split(',').length > 1) return 'Multi-Colored';
      return props.abbreviationManaColorToName(card.colors) || 'None';
    case 'mana':
      return card.convertedManaCost;
    case 'pitch':
      return card.pitch || fleshAndBloodGroupName(card.types);
    default:
      // Default to overview
      switch (props.data.game) {
        case 'pokemon':
          return card.supertype;
        case 'yugioh':
          return `${card.cardType}s`;
        case 'magic':
          return card?.types?.[(card?.types?.length || 1) - 1] || 'Unknown';
        case 'lorcana':
          return card?.types?.length ? `${card.types[card.types.length - 1]}s` : 'Unknown';
        case 'flesh and blood':
          return fleshAndBloodGroupName(card.types);
        default:
          return 'Unknown';
      }
  }
};

watch(
  () => props.sort,
  () => {
    parseDeck();
  }
);


const parseDeck = () => {
  cards.value = deckEmbed.getGrouping(props.data.game, props.sort);
  let type;

  // While we group cards by type, within a type we want a default order so this gets the cards in the order
  // we would want them to appear within each group
  deckEmbed.defaultCardOrder(props.data);

  // Now load them up with cards
  props.data.cards.forEach((card) => {
    type = getSortType(card);

    if (type === 'Unknown') {
      if (!Array.isArray(cards.value.get(type))) cards.value.set(type, []);

      cards.value.set(type, cards.value.get(type).concat([card,]));
    }
    else if (cards.value.has(type)) {
      cards.value.set(type, cards.value.get(type).concat([card,]));
    }
  });
};

const fleshAndBloodGroupName = (types) => {
  if (types?.includes?.('Hero')) {
    return 'Hero';
  }

  if (types?.includes?.('Equipment') || types?.includes?.('Weapon')) {
    return 'Equipment';
  }

  return 'Deck';
};

const setActiveCard = (card) => {
  activeCard.value = card;
  props.setActiveCardCallback(card);
};

const cardCount = (types) => {
  return types.reduce((count, c) => count + c.quantity, 0);
};

const getTypeName = (type) => {
  switch (props.sort) {
    case 'mana': return `${type} Mana`;
    case 'pitch': return isNaN(type) ? type : `${type} Pitch`;
    case 'quantity': return type > 1 ? `${type} Copies` : `${type} Copy`;
    default: return type;
  }
};

onMounted(() => {
  if (!props.typed) { return; }
  parseDeck();

});

</script>

<style lang="scss" scoped>
.types,
.subdeck-header,
.special-header {
  background-color: #f2f9ff;
  height: 26px;
  display: flex;
  align-items: center;
  margin-bottom: $martech-spacer-2;

  p {
    padding-left: $martech-spacer-3;
    font-size: $martech-type-14;
    font-weight: $martech-weight-bold;
    letter-spacing: 0.4px;
    color: $martech-black;
    line-height: 24px;
  }

  .section-title {
    display: flex;
    width: 100%;
  }
}

.subdeck-header {
  background-color: #f0f1f2;
}

.special-header {
  background-color: $martech-blue-10;
}

.list {
  list-style: none;
  padding-bottom: $martech-spacer-2;

  &__item {
    display: flex;
    text-align: left;
    transition: all 0.15s ease-in-out;
    font-size: $martech-type-14;
    line-height: 20px;
    margin-bottom: $martech-spacer-1;
    justify-content: space-between;
    align-items: baseline;

    @include breakpoint(1024) {
      align-items: center;
    }

    &--wrapper {
      width: 53%;
      display: flex;

      @include breakpoint(1024) {
        width: 100%;
      }

      div {
        width: 100%;
        display: flex;
      }

      .pkm-set-tag {
        width: auto;
        background-color: $martech-gray-10;
        border-radius: $martech-radius-default;
        padding: 0 $martech-spacer-2;
        margin-left: $martech-spacer-2;
        line-height: 20px;
      }

      :deep(a) {
        color: $martech-black;
        font-weight: $martech-weight-normal;
        font-size: $martech-type-14;
        transition: all 100ms ease-in-out;
        display: flex;
        align-items: center;
        line-height: 20px;

        &:hover {
          text-decoration: none;
          color: $martech-link;
        }
      }

      .paw-group {
        @include breakpoint(1024) {
          display: flex;
        }
      }

      #paw-icon {
        margin-left: $martech-spacer-1;
        width: 12px;
        height: 12px;
      }
    }

    &-quanity {
      padding-right: $martech-spacer-2;
      padding-left: $martech-spacer-1;
      line-height: 20px;
    }

    .mana {
      width: 32%;
      padding: 0 $martech-spacer-2;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      @include breakpoint(1024) {
        width: 38%;
      }

      :deep(.mana-symbols) {
        div {
          svg {
            margin-right: 2px;
          }

          .plus {
            width: 9px;
            height: 9px;
          }
        }
      }
    }

    .lorcana-ink {
      display: flex;
      align-items: center;
    }

    .pitch {
      display: flex;
      min-width: 30px;
      justify-content: flex-start;
    }

    .price {
      width: 15%;
      display: flex;
      justify-content: flex-end;

      :deep(.market-price) {
        p {
          font-size: $martech-type-12;
          color: $martech-gray-170;
          font-weight: $martech-weight-normal;
          line-height: 22px;
        }
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all 250ms ease-in-out;
}

.slide-fade-leave-active {
  transition: all 250ms cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
