<template>
  <base-styles>
    <div v-if="deviceType === 'desktop'" class="card-details-wrapper">
      <div ref="deckEmbedBodyDetails" class="desktop-card-details"
        :class="{ 'event-page': ['event', 'deck'].includes(pageType) }">
        <div v-if="cardData" id="card-modal" class="card-details">
          <image-card loaded :card-data="cardData" :is-flipped="cardData.isFlipped"
            :card-dimensions="{ width: '320px', height: '448px', }" allow-flip
            @flip="cardData.isFlipped = !cardData.isFlipped" /> <!-- eslint-disable-line -->
          <div class="card-details__content">
            <p v-if="cardData.setName" class="martech-text-sm set">
              {{ cardData.setName }}
            </p>
            <p v-if="cardData.rarity" class="martech-text-sm martech-text-capitalize rarity">
              {{ cardData.rarity }}
            </p>
            <p v-if="cardData.displayName" class="martech-body martech-two-lines martech-hyphens card-details__card">
              {{ cardData.displayName }}
            </p>
            <div class="card-details__prices">
              <market-price-template align-text="left" :price="cardData.marketPrice" />
            </div>
            <div v-if="productUrl" class="card-details__button">
              <base-button class="buy-button"
                :link="{ linkUrl: productUrl, linkText: 'Buy on TCGplayer', urlTarget: featureFlagUrlTarget}" btn-style="martech-black"
                btn-size="martech-medium" @clicked="clickedDesktop" />
            </div>
            <div v-if="dashboard">
              <base-button class="copy-to-clipboard" btn-size="martech-small" btn-style="martech-text-underline"
                @clicked="copyToClipboard">
                <copy-icon /> Copy Image to Clipboard
                <span v-show="showToast" class="copy-toast">
                  Copied Image to Clipboard
                </span>
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-styles>
</template>

<script>
import BaseStyles from '@/components/BaseStyles.vue';
import BaseButton from '@/components/elements/BaseButton.vue';
import ImageCard from '@/components/image/Card.vue';
import MarketPriceTemplate from '@/components/MarketPriceTemplate.vue';
import deviceType from '@/mixins/deviceType';
import clipboard from '@/lib/clipboard';
import CopyIcon from '@/components/iconography/CopyIcon.vue';

export default {
  name: 'deck-embed-desktop-card-details',
  components: {
    BaseStyles,
    BaseButton,
    ImageCard,
    MarketPriceTemplate,
    CopyIcon,
  },
  mixins: [deviceType],
  props: {
    deckId: {
      type: String,
      required: true,
      default: '',
    },
    cardData: {
      type: Object,
      required: false,
      // eslint-disable-next-line
      default: () => { return { flipImageURL: '', game: '', imageURL: '', layout: '', marketPrice: 0.00, name: '', rarity: '', tcgProductURL: '', }; }
    },
    show: {
      type: String,
      required: false,
      default: '',
    },
    pageType: {
      type: String,
      required: false,
      default: '',
    },
    productUrl: {
      type: String,
      required: false,
      default: '',
    },
    dashboard: {
      type: Boolean,
      required: false,
      default: false,
    },
    featureFlag: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showToast: false,
    };
  },
  computed: {
    featureFlagUrlTarget() {
      return this.featureFlag ? '' : '_blank';
    }
  },
  created() {
    window.addEventListener('scroll', this.deckOffset);
  },
  unmounted() {
    window.removeEventListener('scroll', this.deckOffset);
  },
  methods: {
    deckOffset() {
      const windowScroll = window.pageYOffset;

      // Setup
      const deckEmbedBody = document.getElementById(this.deckId);
      const deckEmbedCard = this.$refs.deckEmbedBodyDetails;

      // Check for deck embed elements
      if (!deckEmbedBody || !deckEmbedCard) return;

      // Get bounding coords of Deck Embed elements
      const bodyCoords = deckEmbedBody.getBoundingClientRect();
      const cardCoords = deckEmbedCard.getBoundingClientRect();

      // Move child elements inside deck embed column
      if (windowScroll > 0 && bodyCoords.top < -30) {
        deckEmbedCard.classList.add('scroll-start');
      } else {
        deckEmbedCard.classList.remove('scroll-start');
      }

      if (['event', 'deck'].includes(this.pageType)) {
        // Move child elements inside deck embed column on event pages
        if (windowScroll > 0 && bodyCoords.top < 100) {
          deckEmbedCard.classList.add('scroll-start');
        } else {
          deckEmbedCard.classList.remove('scroll-start');
        }
      }

      // Determine Bottom intersection
      const maxOffset = bodyCoords.height - cardCoords.height;

      if (bodyCoords.top * -1 > maxOffset - 350) {
        deckEmbedCard.classList.add('scroll-stop');
      } else {
        deckEmbedCard.classList.remove('scroll-stop');
      }

      // If Deck body is less than 700 remove classes and do not scroll
      if (bodyCoords.height < 700) {
        deckEmbedCard.classList.remove('scroll-start');
        deckEmbedCard.classList.remove('scroll-stop');
      }
    },
    closeModal(close) {
      this.$emit('close-modal');
    },
    clickedDesktop() {
      this.$emit('clicked-desktop');
    },
    toast() {
      this.showToast = true;

      setTimeout(() => {
        this.showToast = false;
      }, 600);
    },
    copyToClipboard() {
      const text = this.cardData.imageURL;
      clipboard.Copy(text);
      this.toast();
    },
  },
};
</script>

<style lang="scss" scoped>
.card-details-wrapper {
  display: flex;
  justify-content: center;

  @include breakpoint(1024) {
    width: 320px;
  }
}

.desktop-card-details {
  position: relative;
  transition: all 0.5s ease;
  width: 100%;
  margin-bottom: $martech-spacer-4;

  @include breakpoint(1024) {
    width: 320px;
  }

  &.scroll-start {
    position: fixed;
    top: 85px;

    &.event-page {
      top: 145px;
    }
  }

  &.scroll-stop {
    position: absolute;
    bottom: 70px;
    top: auto;

    // These look the same, but it is needed for the .event-page class to have the bottom function properly
    &.event-page {
      bottom: 80px;
      top: auto;
    }
  }

  .card-details {
    background-color: $martech-white;
    width: 100%;

    &__content {
      margin-top: $martech-spacer-2;
      overflow: hidden;
      width: 100%;
    }

    &__card {
      font-family: $martech-display-inter;
      font-weight: $martech-weight-semibold;
      color: $martech-text-primary;
      line-height: $martech-type-30;
      overflow-wrap: break-word;
    }

    .set {
      letter-spacing: 0.5px;
      margin: 0;
      color: $martech-text-subdued;
    }

    .rarity {
      letter-spacing: 0.5px;
      margin: 0;
      text-transform: capitalize;
      color: $martech-text-subdued;
      line-height: $martech-type-22;
    }

    &__button {
      width: 100%;
      display: block;
      margin: 0 auto;
      margin-top: $martech-spacer-3;

      .buy-button {
        width: 100%;

        :deep(.martech-button) {
          width: 100%;

          a {
            text-decoration: none;
          }
        }
      }
    }

    &__prices {
      :deep(.title) {
        font-size: $martech-type-13;
        line-height: $martech-type-22;
      }
    }
  }

  &.deck-page {
    @include breakpoint(1200) {
      width: 276px;
    }

    .card-details__card-buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.copy-to-clipboard {
  margin-top: $martech-spacer-3;
  color: $martech-text-subdued;
  position: relative;

  svg {
    margin-right: $martech-spacer-2;
  }
}

.copy-toast {
  animation: toast-close 600ms forwards;
  animation-iteration-count: 1;
  animation-delay: 4s;
  color: $martech-text-primary;
  background: $martech-white;
  border-radius: $martech-radius-default;
  height: 32px;
  width: 150px;
  padding: $martech-spacer-2;
  position: absolute;
  font-size: $martech-type-12;
  z-index: 100;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  top: 30px;
  justify-content: center;

  &::before {
    content: "\A";
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $martech-white;
    left: 35px;
    top: -6px;
  }
}
</style>
