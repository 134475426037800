<template>
  <base-styles>
    <div :data-testid="componentID()">
      <div v-if="loading" class="placeholder-shimmer"/>
      <div v-else class="homepage-hero">
        <base-link
          v-if="!hideButton && data?.buttonURL"
          :link-url="data?.buttonURL"
          :url-target="target"
          :new-window="!!target"
          @click="onClick">
          <div class="container">
            <div class="homepage-hero__wrapper" :class="[alignmentStyles, colorStyles]">
              <div class="hero-content">
                <div class="vertical">
                <p v-if="!hideVerticalLabel && data?.vertical" class="martech-labels">
                  {{ verticalDisplayName }}
                </p>
                </div>
                <p v-if="data?.subtitle" class="martech-body">
                  {{ data?.subtitle }}
                </p>
                <h1 class="martech-title hero-title">
                  {{ data?.title }}
                </h1>
                <p v-if="data?.text" class="martech-text-md" :class="data?.textColor === 'white' ? 'martech-secondary' : 'martech-black'">
                  {{ data?.text }}
                </p>
                <div class="button-group">
                  <base-button v-if="data?.buttonURL" class="hero-button" :btn-style="data?.textColor === 'white' ? 'martech-secondary' : 'martech-black'" btn-size="martech-medium">
                    {{ data?.buttonText }}
                  </base-button>
                  <base-link v-if="data?.termsURL" class="hero-link" :link-url="data?.termsURL" url-target="_blank">
                    {{ data?.termsText }}
                  </base-link>
                </div>
              </div>
            </div>
          </div>
        </base-link>
        <div v-if="hideButton || !data?.buttonURL" class="container">
          <div class="homepage-hero__wrapper" :class="[alignmentStyles, colorStyles]">
            <div class="hero-content">
              <p v-if="!hideVerticalLabel && data?.vertical" class="vertical martech-labels">
                {{ verticalDisplayName }}
              </p>
              <p v-if="data?.subtitle" class="hero-subtitle">
                {{ data?.subtitle }}
              </p>
              <h1 class="hero-title">
                {{ data?.title }}
              </h1>
              <p v-if="data?.text" class="hero-text">
                {{ data?.text }}
              </p>
            </div>
          </div>
        </div>
        <img v-show="heroImageURL" ref="imageRoot" :src="imageURL(heroImageURL, { maxDimensions: 'autoxauto', quality: 75, root: imageRoot })" :alt="data?.title" class="hero-image">
        <div class="radial-gradient"/>
      </div>
    </div>
  </base-styles>
</template>

<script setup>
import { computed, ref } from 'vue';
import { get } from '@vueuse/core';
import useDeviceType from '@/use/deviceType';
import useComponentId from '@/use/useComponentId';
import useImageAlter from '@/use/image/alter';
import verts from '@/lib/verticals.js';

import BaseStyles from '@/components/BaseStyles.vue';
import BaseLink from '@/components/elements/BaseLink.vue';
import BaseButton from '@/components/elements/BaseButton.vue';

const props = defineProps({
    data: {
      type: Object,
      required: true,
    },
    onClick: {
      type: Function,
      required: false,
      default: () => { },
    },
    forceMobileImage: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideVerticalLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideButton: {
      type: Boolean,
      required: false,
      default: false,
    }
});

const { isMobile } = useDeviceType();
const { componentID } = useComponentId();
const { imageURL } = useImageAlter();
const imageRoot = ref();

const alignmentStyles = computed(() => {
  switch (props.data?.textAlignment) {
    case 'center':
    case 'right':
      return props.data?.textAlignment;
    default:
      return 'left';
  }
});

const colorStyles = computed(() => {
  switch (props.data?.textColor) {
    case 'black':
      return props.data?.textColor;
    default:
      return 'white';
  }
});

const target = computed(() => {
  if (props.data?.newTab) {
    return '_blank';
  }

  return '';
});

const heroImageURL = computed(() => {
  return get(isMobile) || props.forceMobileImage ? props.data?.mobileImageURL : props.data?.imageURL;
});

const verticalDisplayName = computed(() => {
  return verts.displayName(props.data?.vertical);
});
</script>

<style lang="scss" scoped>
.homepage-hero-no-data {
  width: 100%;
  min-height: 414px;

  @include breakpoint(1024) {
    min-height: 358px;
  }
}

.container {
  @container (min-width: 360px) {
    width: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding: 0 1rem;
  }
}

.homepage-hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  position: relative;
  background-color: $martech-black;
  background-position: 50%;
  height: 320px;

  :deep(span) {
    display: inherit;

    a {
      width: 100%;
      margin: 8px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding: $martech-spacer-5 0;

    .martech-body {
      margin: 0;
    }

    .hero-title {
      padding: $martech-spacer-2 0;
      margin: 0;
      max-width: 22ch;
      font-family: $martech-display-semi-condensed;

      @include breakpoint(1024) {
        max-width: 35ch;
      }

      @container (min-width:360px) {
        max-width: 22ch;
      }
    }

    .martech-title {
      @container (min-width:360px) {
        line-height: 125%;
        font-weight: 700;
        margin: 0;
        font-size: 25.63px;
      }

    }

    .hero-button {
      margin-top: $martech-spacer-3;
    }

    .vertical {
      background-color: $martech-black;
      border-radius: 16px;
      display: inline-block;
      padding: $martech-spacer-1 $martech-spacer-3;
      margin-bottom: $martech-spacer-2;
    }

    .button-group {
      display: inline-flex;
      flex-direction: column;
    }

    .hero-link {
      :deep(a) {
        font-family: $martech-display-inter;
        font-size: $martech-type-14; // 14px
        line-height: 1.25rem; // 20px
        letter-spacing: 0.4px;
        font-weight: $martech-weight-normal;
        padding-top: $martech-spacer-3;
        transition: underline 100ms ease-in-out;
      }
    }

    &.white {
      .hero-content {
        text-shadow: 1px 1px $martech-black, 0px 0px 16px $martech-black;
        color: $martech-white;

        h1,
        p,
        :deep(a) {
          color: $martech-white;
        }

        .martech-labels {
          color: $martech-white;
        }

        .hero-link {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &.black {
      .hero-content {

        h1,
        p,
        :deep(a) {
          color: $martech-black;
        }

        .martech-labels {
          color: $martech-white;
        }

        .hero-link {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &.center {
      align-items: center;

      .hero-content {
        text-align: center;
      }
    }

    &.left {
      align-items: flex-start;

      .hero-content {
        text-align: left;

        @include breakpoint(1024) {
          width: 50%;
        }

        @container (min-width:360px) {
          width: auto;
        }
      }
    }

    &.right {
      align-items: flex-end;

      .hero-content {
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include breakpoint(1024) {
          width: 50%;
        }

        @container (min-width:360px) {
          width: auto;
        }
      }
    }
  }

  .hero-image {
    position: absolute;
    object-fit: cover;
    object-position: top center;
    width: 100%;
    height: 320px;
    top: 0;
    bottom: 0;
    z-index: 0;
  }

  .radial-gradient {
    background: radial-gradient(transparent, rgba(0, 0, 0, 0.2));
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

.placeholder-shimmer {
  background: linear-gradient(-45deg, $martech-gray-30, $martech-gray-5, $martech-gray-30);
  background-position: left;
  background-size: 400%;
  animation: placeholder-shimmer 3.25s cubic-bezier(.39, .15, .64, .86) infinite;
  height: 320px;
}

@keyframes placeholder-shimmer {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
</style>