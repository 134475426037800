<template>
  <div ref="root" class="article-hero" :data-testid="componentID()">
    <router-link v-show="internalTags.includes('channelfireball')" to="/magic-the-gathering/channelfireball" class="logo-link" tabindex="0">
      <div class="cfb-logo-wrapper">
        <img :src="cfbLogo" alt="ChannelFireball" class="cfb-logo">
      </div>
    </router-link>
    <img v-if="hasRoot" :src="imageURL(image || fallbackImage, { maxDimensions: 'autoxauto', quality: 75, root })" :alt="title" :class="[{'fallback' : !image}, 'image']" @error="errorImageFallback($event)">
    <router-link v-show="showSeries && overlayImageURL && taxonomyName" :to="seriesRoute" class="series-logo-link" tabindex="0">
      <img :src="overlayImageURL" :alt="taxonomyName" class="series-logo">
    </router-link>
    <div class="martech-card-overlay"/>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { ImageHelper, useImageAlter } from '@tcgplayer/martech-components';
import useSeriesRoute from '@/use/series/useSeriesRoute';
import api from '@/api/api';

const props = defineProps({
  data: {
    type: Object,
    required: true,
    default: null,
  },
  vertical: {
    type: String,
    required: true,
    default: '',
  },
  internalTags: {
    type: Array,
    default: () => [],
  },
});

const root = ref();

const { imageURL, hasRoot } = useImageAlter();

const image = computed(() => props.data?.imageURL || '');
const title = computed(() => props.data?.title || '');
const taxonomyName = computed(() => props.data?.taxonomyName || '');
const overlayImageURL = computed(() => props.data?.overlayImageURL || '');
const fallbackImage = computed(() => ImageHelper.getFallbackImage(props.vertical, 'hero'));
const { seriesRoute } = useSeriesRoute(taxonomyName);

const cfbLogo = 'https://mktg-assets.tcgplayer.com/content/channel-fireball/site-images/cfb-orange-white.svg';

const errorImageFallback = (event) => {
  event.target.src = fallbackImage.value;
};

const showSeries = ref(false);
// Note that we are fetching 3 to match the number of articles in the carousel on the bottom of the
// article page.  This means we get caching if the user scrolls to the end of the article.
api.getSeriesArticles(taxonomyName.value, [ 'Article', 'Video', 'Podcast' ], props.data.uuid || '', 0, 3)
  .then((response) => {
    showSeries.value = !!response?.data?.count;
  });
</script>

<style lang="scss" scoped>
.article-hero {
  width: 100%;
  height: 200px;
  position: relative;
  margin-bottom: $martech-spacer-4;

  @include breakpoint(768) {
    height: 520px;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    padding: 0;
  }

  .cfb-logo-wrapper {
    width: 100%;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $martech-spacer-2 $martech-spacer-3;
    background-color: rgb(0,0,0, 0.5);

    @include breakpoint(1024) {
      padding: $martech-spacer-3;
      height: auto;
    }

    .cfb-logo {
      height: 20px;

      @include breakpoint(1024) {
        height: 30px;
      }
    }
  }

  .series-logo-link {
    position: absolute;
    bottom: 0;
    width: 192px;
    min-height: 85px;
  }

  .series-logo {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: $martech-spacer-3;
    z-index: 10;
    width: 192px;
  }

  .martech-card-overlay {
    height: 150px;
  }
}
</style>
