<template>
  <div class="buy-button">
    <form :id="data.id + '-mass-entry-form'" :action="massEntry" method="POST" :target="featureFlagUrlTarget">
      <input :id="data.id + '-c'" v-model="params" type="hidden" name="c">
    </form>
    <base-button class="buy-button" type="submit" btn-style="martech-primary" btn-size="martech-medium" :full-width="fullWidthBtn" @clicked="submit">
      Buy Deck
    </base-button>
  </div>
</template>

<script>
import BaseButton from '@/components/elements/BaseButton.vue';
import link from '@/lib/link';

export default {
  name: 'deck-embed-buy-button',
  components: {
    BaseButton,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => { },
    },
    analyticsData: {
      type: Object,
      required: false,
      // eslint-disable-next-line
      default: () => { return { analyticsCampaign: '', analyticsSource: '', analyticsMedium: '' }; }
    },
    fullWidthBtn: {
      type: Boolean,
      default: false,
    },
    featureFlag: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      params: '',
    };
  },
  computed: {
    featureFlagUrlTarget() {
      return this.featureFlag ? '' : '_blank';
    },
    productLine() {
      switch (this.data.game) {
        case 'lorcana': return 'lorcana tcg';
        case 'flesh and blood': return 'flesh %26 blood tcg';
        default: return this.data.game;
      }
    },
    massEntry() {
      let data = {};

      if (this.analyticsData?.analyticsCampaign || this.analyticsData?.analyticsSource || this.analyticsData?.analyticsMedium) {
        data = {
          utm_campaign: this.analyticsData?.analyticsCampaign,
          utm_source: this.analyticsData?.analyticsSource,
          utm_medium: this.analyticsData?.analyticsMedium,
        }
      } else {
        data = this.analyticsData; // expecting utm_* properties to already be set
      }

      return link.urlUtmBuilder(`${import.meta.env.VITE_TCGPLAYER_MASS_ENTRY_URL}?productline=${this.productLine}`, data);
    },
  },
  mounted() {
    const subDecks = Object.values(this.data.subDecks);
    const params = [];

    subDecks.forEach((subDeck) => {
      subDeck.forEach((card) => {
        if (this.data.game == 'pokemon' && parseInt(card.tcgPlayerID) > 0) {
          params.push(`${card.quantity}-${card.tcgPlayerID}`);
          return;
        }
        params.push(`${card.quantity} ${card.displayName}`);
      });
    });

    this.params = params.join('||');
  },
  methods: {
    submit() {
      this.$emit('buy-deck-event');
      document.getElementById(`${this.data.id}-mass-entry-form`).submit();
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.buy-button {
  width: 100%;

  :deep(button) {
    width: 100%;

    @include breakpoint(768) {
      width: 147px;
    }
  }

}
</style>
