<template>
  <div class="export-market-purchase">
    <social-share-icons
     v-if="includeShareIcons"
      class="share"
      :title="title"
      :author="author"
      :canonical-url="deckCanonical"
      placement="Deck Embed"
      :vertical="vertical"/>
    <deck-embed-price :text-align="textAlign">
      {{ formattedMarketPriceHigh }}
    </deck-embed-price>
    <div class="button-group">
      <export-select v-if="exportButtons" :deck="deck" />
      <deck-buy-button
        :data="deck"
        :analytics-data="analyticsData"
        :full-width-btn="fullWidthBtn"
        :feature-flag="featureFlag"
        @buy-deck-event="buyEvent"/>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import ExportSelect from '@/components/embeds/deck-parts/ExportSelect.vue';
import DeckEmbedPrice from '@/components/embeds/deck-parts/DeckEmbedPrice.vue';
import DeckBuyButton from '@/components/embeds/deck-parts/BuyDeckButton.vue';
import SocialShareIcons from '@/components/share/SocialShare.vue';
import prices from '@/lib/prices';

const emit = defineEmits(['buy-deck-event']);

const buyEvent = () => {
  emit('buy-deck-event');
}

const props = defineProps({
  deck: {
    type: Object,
    required: true,
    default: () => {},
  },
  exportButtons: {
    type: Boolean,
  },
  analyticsData: {
    type: Object,
    default: () => ({ analyticsCampaign: '', analyticsSource: '', analyticsMedium: '' })
  },
  textAlign: {
    type: String,
    default: 'left',
  },
  title: {
    type: String,
  },
  author: {
    type: String,
  },
  vertical: {
    type: String,
  },
  includeShareIcons: {
    type: Boolean,
    default: true,
  },
  fullWidthBtn: {
    type: Boolean,
    default: false,
  },
  featureFlag: {
    type: Boolean,
    required: false,
  }
});

const formattedMarketPriceHigh = computed(() => (prices.marketPrice(props.deck?.stats?.price?.marketPrice || props.deck.marketPriceHigh)));
let deckCanonical = props.deck.canonicalURL;
if (props.deck.external) {
  deckCanonical += `?external=${props.deck.external}`;
}
</script> 

<style lang="scss" scoped>
.export-market-purchase {
  .button-group {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-top: $martech-spacer-2;

    @include breakpoint(1024) {
      flex-direction: row;
    }

    :deep(.export-select__text) {
      padding: 0 0 0 $martech-spacer-3;

      @include breakpoint(1024) {
        padding: 0 $martech-spacer-3 0 0;
      }
    }
  }

  .social-share {
    margin: $martech-spacer-3 0;

    @include breakpoint(1024) {
      display: flex;
      justify-content: right;
      margin: 0 0 $martech-spacer-3 0;
    }

    :deep(.copy-to-clipboard) {
      a {
        margin-right: 0;
      }
    }
  }

  :deep(.deck-price) {
    margin-top: $martech-spacer-2;

    span {
      display: flex;
      justify-content: right;
    }
  }

  :deep(.buy-button) {
    display: flex;
    justify-content: right;
  }

  :deep(.martech-button) {
    font-size: $martech-detail;
  }
}
</style>
