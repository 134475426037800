<template>
  <base-styles>
    <div class="card-spotlight" :class="{ 'is-popup': popup }" :data-testid="componentID()" :style="[{ width: width }, { 'max-width': maxWidth }]">
      <div class="card-spotlight__base">
        <div class="group">
          <div class="card-details" :class="{ 'minified': priceHistory }">
            <h2>{{ data.name || data.productName }} <flip-icon v-if="showFlipButton" role="button" class="flip-icon" @click="$emit('flip')" />
            </h2>
            <p class="set-rarity">
              <span v-if="priceHistory">{{ getDisplayName(data.game) }}&nbsp;</span>{{ data.setName || data.set }}<span v-if="data.rarity && !priceHistory" class="martech-text-capitalize">, {{ data.rarity}}</span>
            </p>
          </div>
          <base-button 
            v-if="productURL && !priceHistory && deviceType === 'desktop' && !optimizelyFlag"
            btn-style="martech-black" 
            btn-size="martech-medium" 
            class="buy-button"
            :icon-classes="iconClasses"
            :link="{ linkText: 'Buy on TCGplayer', linkUrl: productURL, urlTarget: featureFlagUrlTarget }"
            @click.native="$emit('amplitude-event', activeTab)" />
        </div>
      </div>
      <div class="card-spotlight__columns">
        <div class="card-spotlight__column">
          <template v-if="$slots['slotted-image']">
            <slot name="slotted-image" />
          </template>
          <template v-else>
            <image-card 
              v-if="isProduct" 
              :loaded="loaded" 
              :card-data="data" 
              :product-image-url="productImageUrl"
              :product-image-alt-text="productImageAltText" 
              :is-product="true"
              :card-dimensions="{ width: imageDimensions.width, height: imageDimensions.height }" 
              @flip="$emit('flip')" />
            <image-card 
              v-else 
              :loaded="loaded" 
              :card-data="data" 
              :product-image-url="productImageUrl"
              :product-image-alt-text="productImageAltText" 
              :flip-is-icon="flipIsIcon" :allow-flip="false"
              :card-dimensions="{ width: imageDimensions.width, height: imageDimensions.height }" 
              :is-flipped="isFlipped"
              @flip="$emit('flip')" />
          </template>
        </div>
        <div class="card-spotlight__column">
          <template v-if="!priceHistory">
            <tabs :tabs="tabs" :on-change="onTabChange" :default-tab="defaultTab" class="tabs" :large-tabs="false" />
            <div class="product-details" :aria-labelledby="activeTab" role="tabpanel">
              <div v-if="activeTab === 'details'" class="martech-card-spotlight__details">
                <p class="details-header martech-text-semibold">Product Details</p>
                <div v-for="d in cardDataSections" :key="d" class="description-wrapper">
                  <div v-if="data.game && !isProduct" class="mana-type">
                    <mana-cost v-if="d.mana && d.mana.length > 1" :mana-cost="d.mana" />
                    <p v-if="d.cost" class="details-header">
                      Cost: <span>{{ d.cost }}</span>
                    </p>
                    <p v-if="d.type?.length > 1" class="details-header">
                      Card Type: <span>{{ d.type }}</span>
                    </p>
                  </div>
                  <card-description 
                    :vertical="data.game" 
                    :description="d.description" 
                    collapsible 
                    :popup="popup"
                    :truncate="truncateLengthByGame(data.game)" @opened="descriptionOpenCheck" />
                  <div :class="{ 'description-open': descriptionOpen }">
                    <div class="market-price" v-if="optimizelyVariantTwo">
                      <market-price-template :price="data.marketPrice" align-text="left" />
                    </div>
                    <price-points v-else :price-points="data.pricePoints" />
                  </div>
                </div>
              </div>
              <div v-else class="card-spotlight__graph">
                <price-history 
                  :disable-settings="disablePriceHistorySettings"
                  :disable-timeframe="disablePriceHistoryTimeframe" 
                  :product-id="getCardId"
                  :condition="condition"
                  :language="language"
                  :printing="printing"
                  :display-volume="true"
                  display-card-title
                  :card-name="data.name"
                  :set-name="data.setName"
                  :allow-skip-days="false" />
              </div>
              <base-button 
                v-if="productURL && !priceHistory && deviceType === 'mobile' && !optimizelyFlag"
                btn-style="martech-black" 
                btn-size="martech-medium" 
                class="buy-button"
                :class="{ 'mobile-btn': deviceType === 'mobile' }"
                :icon-classes="iconClasses"
                :link="{ linkText: 'Buy on TCGplayer', linkUrl: productURL, urlTarget: featureFlagUrlTarget }"
                @clicked="$emit('amplitude-event', activeTab)" />
              <!--extra button for optimizely testing purposes-->
              <base-button 
                v-if="productURL && !priceHistory && optimizelyFlag" 
                btn-style="martech-black"
                btn-size="martech-medium" class="buy-button test-button"
                :class="{ 'mobile-btn': deviceType === 'mobile' }"
                :icon-classes="iconClasses"
                :link="{ linkText: 'Buy on TCGplayer', linkUrl: productURL, urlTarget: featureFlagUrlTarget }"
                @clicked="$emit('amplitude-event', activeTab)" />
            </div>
          </template>
          <template v-else>
            <slot />
          </template>
        </div>
      </div>
    </div>
  </base-styles>
</template>

<script>
import delve from 'dlv';
import BaseStyles from '@/components/BaseStyles.vue';
import Tabs from '@/components/elements/Tabs.vue';
import BaseButton from '@/components/elements/BaseButton.vue';
import PriceHistory from '@/components/price-history/History.vue';
import ImageCard from '@/components/image/Card.vue';
import CardDescription from '@/components/product-details/parts/CardDescription.vue';
import deviceType from '@/mixins/deviceType';
import ManaCost from '@/components/product-details/parts/ManaCost.vue';
import PricePoints from '@/components/product-details/parts/PricePoints.vue';
import componentId from '@/mixins/componentId';
import FlipIcon from '@/components/iconography/FlipIcon.vue';
import MarketPriceTemplate from '@/components/MarketPriceTemplate.vue';
import verts from '@/lib/verticals';

export default {
  name: 'martech-card-spotlight',
  components: {
    BaseStyles,
    Tabs,
    BaseButton,
    PriceHistory,
    ImageCard,
    CardDescription,
    ManaCost,
    FlipIcon,
    PricePoints,
    MarketPriceTemplate
  },
  mixins: [deviceType],
  props: {
    data: {
      type: Object,
      required: false,
      default: () => ({
        name: '',
        flipImageURL: '',
        imageURL: '',
        layout: '',
        marketPrice: '',
        pricePoints: {
          Normal: {},
          Foil: {},
        },
        rarity: '',
        setName: '',
        description: '',
        productUrl: '',
        tcgPlayerID: '',
        typeLine: '',
        convertedManaCost: '',
        manaCost: '',
        cost: '',
        cardType: '',
      }),
    },
    productImageUrl: {
      type: String,
      required: false,
      default: false,
    },
    productImageAltText: {
      type: String,
      required: false,
      default: false,
    },
    pricePoints: {
      type: [Object, String],
      default: () => ({}),
      required: false,
    },
    latestSales: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    pricePointsColumns: {
      type: Array,
      default: () => [],
      required: true,
    },
    latestSalesColumns: {
      type: Array,
      default: () => [],
      required: true,
    },
    isProduct: {
      type: Boolean,
      required: false,
      default: false,
    },
    loaded: {
      type: Boolean,
      required: false,
      default: false,
    },
    popup: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFlipped: {
      type: Boolean,
      required: false,
      default: false,
    },
    flipIsIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    cardProductUrl: {
      type: String,
      default: '',
      required: false,
    },
    cardId: {
      type: [Number, String,],
      default: '',
      required: false,
    },
    defaultTab: {
      type: String,
      default: 'details',
      required: false,
    },
    width: {
      type: String,
      required: false,
      default: '100%',
      notes: 'allows you to set the width of the card in percents or pixels.'
    },
    maxWidth: {
      type: String,
      required: false,
      default: '100%',
    },
    priceHistory: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Turns the card to its minified version with price points and latest sales tables.'
    },
    imageDimensions: {
      type: Object,
      required: false,
      default: () => ({
        width: '314px',
        height: '439px',
      }),
    },
    optimizelyFlag: {
      type: Boolean,
      default: false,
    },
    disablePriceHistorySettings: {
      type: Boolean,
      default: false,
    },
    disablePriceHistoryTimeframe: {
      type: Boolean,
      default: false,
    },
    printing: {
      type: Array,
      default: null,
    },
    condition: {
      type: Array,
      default: null,
    },
    language: {
      type: Array,
      default: null,
    },
    featureFlag: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  data() {
    return {
      activeTab: '',
      descriptionOpen: false,
      tabs: [
        {
          title: 'details',
        },
        {
          title: 'price graph',
        }
      ],
    };
  },
  computed: {
    featureFlagUrlTarget() {
      return this.featureFlag ? '' : '_blank';
    },
    iconClasses() {
      return this.featureFlag ? '' : 'martech-icon martech-right martech-external-link-icon martech-small martech-white';
    },
    optimizelyVariantTwo() {
      if (this.optimizelyFlag.marketPriceOnly) return true;
      return false;
    },
    showFlipButton() {
      return delve(this, 'data.layout', '').match(/transform|modal_dfc/);
    },
    productURL() {
      return this.cardProductUrl
      || delve(this, 'data.productUrl')
      || delve(this, 'data.tcgProductUrl', '');
    },
    getCardId() {
      return this.data.tcgPlayerID || this.cardId;
    },
    cardDataSections() {
      const names = delve(this, 'data.name', '').split(' // ');
      const manas = delve(this, 'data.manaCost', '').split(' // ');
      const types = (this.data?.cardType || this.data?.typeLine || '').split(' // ');
      const descriptions = delve(this, 'data.description', '').split(' // ');
      const game = this.data?.game || '';
      const cost = this.data?.cost || '';

      const data = [];

      let name = names[0] || '';
      let mana = manas[0] || '';
      let type = types[0] || '';
      let description = descriptions[0] || '';

      const max = Math.max(names.length, manas.length, types.length, descriptions.length);

      for (let i = 0; i < max; i++) {
        name = names[i] || names || '';
        mana = manas[i] || manas || '';
        type = types[i] || types || '';
        description = descriptions[i] || '';

        data[i] = {
          name,
          game,
          mana,
          cost,
          type,
          description,
        };
      }

      return data;
    },
  },
  created() {
    this.activeTab = this.defaultTab;
  },
  methods: {
    descriptionOpenCheck(open) {
      this.descriptionOpen = open;
    },
    getDisplayName(game) {
      return verts.displayName(game || '');
    },
    onTabChange(tab) {
      this.activeTab = tab.toLowerCase();
    },
    truncateLengthByGame(game) {
      if (game === 'pokemon') {
        return 400;
      }

      return 500;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-spotlight {
  border-radius: $martech-radius-medium;
  border: 1px solid $martech-border;
  padding: $martech-spacer-3;
  font-family: $martech-display-inter;
  display: block;
  margin: auto;
  text-decoration: none;
  color: $martech-text-primary;
  background: $martech-surface;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.20);

  @include breakpoint(1024) {
    padding: $martech-spacer-4;
  }

  .martech-icon {
    width: 12px;
  }

  :deep(.martech-button) {
    @include breakpoint(1024) {
      min-width: 212px;
    }
  }

  &__base {
    margin-bottom: $martech-spacer-3;

    .group {
      display: flex;
      justify-content: space-between;
    }

    h2 {
      font-family: $martech-display-inter;
      font-size: $martech-type-20;
      font-weight: $martech-weight-bold;
      line-height: $martech-type-24;

      @include breakpoint(1024) {
        font-size: $martech-type-22;
        line-height: 1.75rem;
      }
    }

    .flip-icon {
      margin-left: $martech-spacer-2;
      color: $martech-text-subdued;
      cursor: pointer;
    }

    .set-rarity {
      color: $martech-text-subdued;
      font-size: $martech-type-13;
      font-weight: 400;
    }
  }

  .description-open {
    transition: padding-top 300ms ease-out;
    padding-top: $martech-spacer-4;
  }

  .market-price {
    padding-top: $martech-spacer-3;
    font-weight: $martech-weight-semibold;
  }

  .mobile-btn {
    :deep(.martech-button) {
      margin-top: $martech-spacer-3;
      width: 100%;
    }
  }

  &__columns {
    display: flex;
    flex-direction: column;

    @include breakpoint(1024) {
      flex-direction: row;
    }
  }

  &__column {
    &:first-of-type {
      margin-bottom: $martech-spacer-2;
      display: flex;
      justify-content: center;

      @include breakpoint(1024) {
        margin-bottom: 0;
        margin-right: $martech-spacer-5;
      }
    }

    &:last-of-type {
      @include breakpoint(1024) {
        width: 65%;
      }
    }
  }

  .tabs {
    width: 100%;

    :deep(.martech-tabs) {
      margin-bottom: $martech-spacer-4;
    }
  }

  .product-details {
    width: 100%;
    height: 100%;
  }

  &__image {
    border-radius: $martech-radius-medium;
    width: 100%;
    display: flex;
    height: 300px;
    margin-bottom: $martech-spacer-2;
    justify-content: center;

    @include breakpoint(768) {
      margin-bottom: 0;
      justify-content: flex-start;
    }
  }

  :deep(.card-image--front) {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 8px;
  }

  :deep(.price-points-table) {
    max-width: 440px;
    padding: 1.5rem 0 0;
  }

  &__details {
    width: 100%;

    .description-wrapper {
      &:last-of-type {
        .description-hr {
          display: none;
        }
      }
    }

    .mana-type {
      :deep(.energy) {
        width: 12px;
        height: 12px;
        vertical-align: middle;
        display: inline-block;
        margin-bottom: 3px;
      }
    }
  }

  .details-header {
    font-weight: $martech-weight-semibold;
    padding-bottom: $martech-spacer-3;
    font-size: $martech-type-14;
    line-height: $martech-type-18;

    span {
      font-weight: $martech-weight-normal;
      margin-left: $martech-spacer-2;
    }
  }

  .test-button {
    :deep(.martech-button) {
      width: 100%;
      margin-top: $martech-spacer-3;
      text-align: center;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: $martech-spacer-4;

    @include breakpoint(768) {
      justify-content: flex-end;
      flex-direction: row;
      align-items: center;
      margin-top: 0;
    }

    p {
      margin: 0;
      padding-bottom: $martech-spacer-2;
      font-weight: $martech-weight-bold;
      letter-spacing: 0.25px;

      @include breakpoint(768) {
        padding-right: $martech-spacer-3;
        padding-bottom: 0;
      }

      span {
        color: $martech-mint-dark;
      }
    }
  }
}
</style>
