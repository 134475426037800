<template>
  <div class="navigation-logo" :data-testid="componentID()">
    <base-link v-if="$route.meta.changeNav === 'subscriptions'" :link-url="marketplaceURL">
      <img :src="tcgplayerFanLogo" alt="TCGplayer" class="subscriptions">
    </base-link>
    <base-link v-else :link-url="currentUrl || '#'">
      <img :src="siteConfig('navigation.logo')" :alt="siteConfig('navigation.logoAltText')" :class="{'custom-width' : siteConfig('navigation.customLogoWidth')}">
    </base-link>
  </div>
</template>

<script>
import { BaseLink } from '@tcgplayer/martech-components';

export default {
  name: 'navigation-logo',
  components: {
    BaseLink,
  },
  data() {
    return {
      tcgplayerFanLogo: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/tcgplayer-logo-full-color.svg',
      currentUrl: '/',
      marketplaceURL: process.env.VUE_APP_TCGPLAYER_ROOT_URL,
    };
  },
  created() {
    this.currentUrl = this.$router.resolve({ name: 'Home' }).href;
  },
};
</script>

<style lang="scss" scoped>
.navigation-logo {
  :deep(a) {
    display: flex;
  }

  img {
    width: 170px;

    @include breakpoint(768) {
      width: 178px;
    }

    @media only screen and (min-width: 1025px) {
      width: 125px;
    }

    @media only screen and (min-width: 1400px) {
      width: 150px;
    }

    &.custom-width {
      width: 175px;
    }
  }

  .subscriptions {
    width: 108px;

    @include breakpoint(1024) {
      width: 131px;
    }
  }
}
</style>
