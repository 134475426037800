<template>
  <div class="page-footer" :data-testid="componentID()">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h3>How Can We Help?</h3>
          <ul>
            <li>
              <base-link :link-url="help.linkURL" new-window url-target="_blank" class="navigation-links martech-body" @click="track(help)">
                {{ help.linkTitle }}
              </base-link>
              <i class="martech-icon martech-external-link-icon martech-white"/>
            </li>
            <li>
              <base-link :link-url="accessibility.linkURL" new-window url-target="_blank" class="navigation-links martech-body" @click="track(accessibility)">
                {{ accessibility.linkTitle }}
              </base-link>
              <i class="martech-icon martech-external-link-icon martech-white"/>
            </li>
            <li>
              <base-link :link-url="ebaySecurity.linkURL" new-window url-target="_blank" class="navigation-links martech-body" @click="track(ebaySecurity)">
                {{ ebaySecurity.linkTitle }}
              </base-link>
              <i class="martech-icon martech-external-link-icon martech-white"/>
            </li>
          </ul>
          <div class="social-icons">
            <h4>Connect With Us</h4>
            <social-media-buttons class="social-icons__buttons"/>
          </div>
        </div>
        <div class="column grid">
          <h4 class="martech-text-md">
            Content
          </h4>
          <ul>
            <li v-for="(item, index) in content" :key="index">
              <base-link :link-url="item.linkUrl" class="navigation-links martech-body" @click="track(content)">
                {{ item.linkTitle }}
              </base-link>
              <i v-if="item.externalLink" class="martech-icon martech-external-link-icon martech-white"/>
            </li>
          </ul>
        </div>
        <div class="column">
          <h4 class="martech-text-md">
            Decks
          </h4>
          <ul>
            <li>
              <base-link :link-url="magicDecks.linkURL" class="navigation-links martech-body" @click="track(magicDecks)">
                {{ magicDecks.linkTitle }}
              </base-link>
            </li>
          </ul>
        </div>
        <div class="column">
          <h4 class="martech-text-md">
            Shop
          </h4>
          <ul>
            <li>
              <base-link :link-url="marketplace.linkURL" new-window url-target="_blank" class="navigation-links martech-body" @click="track(marketplace)">
                {{ marketplace.linkTitle }}
              </base-link>
              <i class="martech-icon martech-external-link-icon martech-white"/>
            </li>
            <li>
              <base-link :link-url="giftCards.linkURL" new-window url-target="_blank" class="navigation-links martech-body" @click="track(giftCards)">
                Gift Cards
              </base-link>
              <i class="martech-icon martech-external-link-icon martech-white"/>
            </li>
            <li>
              <base-link :link-url="massEntry.linkURL" new-window url-target="_blank" class="navigation-links martech-body" @click="track(massEntry)">
                {{ massEntry.linkTitle }}
              </base-link>
              <i class="martech-icon martech-external-link-icon martech-white"/>
            </li>
            <li>
              <base-link :link-url="priceGuide.linkURL" new-window url-target="_blank" class="navigation-links martech-body" @click="track(priceGuide)">
                {{ priceGuide.linkTitle }}
              </base-link>
              <i class="martech-icon martech-external-link-icon martech-white"/>
            </li>
            <li>
              <base-link :link-url="premium.linkURL" class="navigation-links martech-body" @click="track(premium)">
                {{ premium.linkTitle }}
              </base-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>
        All original content herein is Copyright ©{{
          new Date().getFullYear()
        }}
        TCGplayer, Inc. TCGplayer, Inc., TCGplayer.com®, TCGplayer Infinite, and
        ChannelFireball.com are trademarks of TCGplayer, Inc.
        No portion of this website may be used without expressed written consent.
        All rights reserved.<br>
        <base-link :link-url="mtgCopyright.linkURL" new-window url-target="_blank" @click="track(mtgCopyright)">
          {{ mtgCopyright.linkTitle }}
        </base-link>
        and its respective properties are copyright
        <base-link :link-url="wotcCopyright.linkURL" new-window url-target="_blank" @click="track(wotcCopyright)">
          {{ wotcCopyright.linkTitle }}
        </base-link>
      </p>
      <div class="copyright__links">
        <base-link :link-url="terms.linkURL" new-window url-target="_blank" @click="track(terms)">
          {{ terms.linkTitle }}
        </base-link>&nbsp;&nbsp;|&nbsp;&nbsp;
        <base-link :link-url="privacy.linkURL" new-window url-target="_blank" @click="track(privacy)">
          {{ privacy.linkTitle }}
        </base-link>&nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="#" @click="openConsentManager">Website Data Collection</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="#" @click="openConsentManager">Do Not Sell or Share My Personal Information</a>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import SocialMediaButtons from '@/components/footer-elements/SocialMediaButtons.vue';
import amplitudeEvent from '@tcgplayer/amplitude';
import { BaseLink, useFeature } from '@tcgplayer/martech-components';
import { useUser } from '@/use/useUser';

export default {
  name: 'page-footer',
  components: {
    SocialMediaButtons,
    BaseLink,
  },
  setup() {
    const { isOptimizelyFeatureEnabled } = useFeature();
    const { userKey } = useUser();
    const isSubsMigrationEnabled = isOptimizelyFeatureEnabled('mt-subs-migration', { userKey });

    const premium = computed(() => {
      const p = {
        linkTitle: 'TCGplayer Subscription',
        linkURL: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/subscription`,
        parentTitle: 'Shop',
      };

      if (isSubsMigrationEnabled.value) {
        p.linkURL = `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/subscription`;
      }

      return p;
    });

    return {
      premium,
    };
  },
  data() {
    return {
      marketplace: {
        linkTitle: 'Shop TCGplayer',
        linkURL: process.env.VUE_APP_TCGPLAYER_ROOT_URL,
        parentTitle: 'Shop',
      },
      wotcCopyright: {
        linkTitle: 'Wizards of the Coast',
        linkURL: process.env.VUE_APP_WOTC_URL,
      },
      mtgCopyright: {
        linkTitle: 'Magic: The Gathering',
        linkURL: process.env.VUE_APP_WOTC_MTG_URL,
      },
      terms: {
        linkTitle: 'Terms of Service',
        linkURL: `${process.env.VUE_APP_TCGPLAYER_HELP_URL}/hc/en-us/articles/205004918-Terms-of-Service`,
      },
      privacy: {
        linkTitle: 'Privacy Policy',
        linkURL: 'https://tcgplayer.com/privacypolicy',
      },
      priceGuide: {
        linkTitle: 'Price Guide',
        linkURL: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/categories/trading-and-collectible-card-games/magic-the-gathering/price-guides`,
        parentTitle: 'Shop',
      },
      giftCards: {
        linkTitle: 'Gift Cards',
        linkURL: `${process.env.VUE_APP_TCGPLAYER_SHOP_URL}/giftcard`,
        parentTitle: 'Shop',
      },
      massEntry: {
        linkTitle: 'Mass Entry',
        linkURL: process.env.VUE_APP_TCGPLAYER_MASS_ENTRY_URL,
        parentTitle: 'Shop',
      },
      help: {
        linkTitle: 'Help Center',
        linkURL: `${process.env.VUE_APP_TCGPLAYER_HELP_URL}/hc/en-us`,
        parentTitle: 'How Can We Help?',
      },
      accessibility: {
        linkTitle: 'Accessibility',
        linkURL: `${process.env.VUE_APP_TCGPLAYER_HELP_URL}/hc/en-us/articles/4419756533527-Accessibility-Statement`,
        parentTitle: 'How Can We Help?',
      },
      ebaySecurity: {
        linkTitle: 'Security Researchers - Responsible Disclosure',
        linkURL: 'https://pages.ebay.com/securitycenter/security_researchers.html',
        parentTitle: 'How Can We Help?',
      },
      magicDecks: {
        linkTitle: 'Magic: The Gathering Decks',
        linkURL: '/magic-the-gathering/decks',
        parentTitle: 'Decks & Events',
      },
      content: [
        {
          linkTitle: 'Edge',
          linkUrl: `${process.env.VUE_APP_TCGPLAYER_INFINITE_URL}/tcgplayer-edge`,
          parentTitle: 'Content',
        },
        {
          linkTitle: 'Standard',
          linkUrl: '/magic-the-gathering/standard',
          parentTitle: 'Content',
        },
        {
          linkTitle: 'Modern',
          linkUrl: '/magic-the-gathering/modern',
          parentTitle: 'Content',
        },
        {
          linkTitle: 'Pioneer',
          linkUrl: '/magic-the-gathering/pioneer',
          parentTitle: 'Content',
        },
        {
          linkTitle: 'Limited',
          linkUrl: '/magic-the-gathering/limited',
          parentTitle: 'Content',
        },
        {
          linkTitle: 'Legacy',
          linkUrl: '/magic-the-gathering/legacy',
          parentTitle: 'Content',
        },
        {
          linkTitle: 'Magic: The Gathering',
          linkUrl: '/magic-the-gathering',
          parentTitle: 'Content',
        },
        {
          linkTitle: 'Flesh and Blood',
          linkUrl: `${process.env.VUE_APP_TCGPLAYER_INFINITE_URL}/flesh-and-blood`,
          parentTitle: 'Content',
          externalLink: true,
        },
        {
          linkTitle: 'Archive',
          linkUrl: '/archive',
          parentTitle: 'Content',
        },
      ],
    };
  },
  methods: {
    track(link) {
      const { linkTitle, linkURL, parentTitle } = link;

      amplitudeEvent('general', 'footerClicked', {
        linkTitle,
        linkURL,
        parentTitle,
      });
    },
    openConsentManager(ev) {
      ev.preventDefault();
      if (!window.consentManager) return;
      window.consentManager.openConsentManager();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-footer {
  background-color: $martech-gray-150;
  padding: 2.25rem 0 0; //36px
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;

  .columns {
    display: flex;
    flex-direction: column;
    padding-bottom: $martech-spacer-4;

    @include breakpoint(768) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: auto;
      grid-gap: 24px;
    }
  }

  .column {
    margin-bottom: $martech-spacer-4;

    &.grid {
      ul {
        @include breakpoint(768) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: $martech-spacer-2;
          grid-auto-flow: dense;
        }
      }

      li {
        @include breakpoint(768) {
          padding-bottom: 0;

          &:nth-of-type(1),
          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4),
          &:nth-of-type(5),
          &:nth-of-type(6) {
            grid-column: 1/1;
          }
        }
      }
    }

    @include breakpoint(768) {
      margin-bottom: 0;
    }

    h3 {
      color: $martech-cfb-orange-base;
      margin-bottom: $martech-spacer-2;
    }
    h4,
    h3 {
      font-weight: $martech-weight-medium;
      font-size: $martech-type-18;
      letter-spacing: -0.017em;
      margin-bottom: $martech-spacer-3;
      margin-top: 0;
    }

    h4 {
        color: $martech-white;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-top: 0;
    }

    li {
      display: flex;
      padding-bottom: $martech-spacer-2;
      align-items: center;

      :deep(a) {
        font-size: $martech-type-14;
        color: $martech-white;
        line-height: 1;
        font-weight: $martech-weight-normal;
        text-decoration: none;
        transition: color 300ms ease-in-out;

        :deep(&:hover) {
          color: $martech-blue-hover;
        }
      }

      .martech-icon {
        width: 12px;
        height: 12px;
        margin-left: $martech-spacer-2;
      }
    }
  }

  .social-icons {
    padding-top: $martech-spacer-3; //36px

    h4 {
      font-weight: $martech-weight-medium;
      font-size: $martech-type-18;
      letter-spacing: -0.017em;
      margin-bottom: $martech-spacer-3;
      margin-top: 0;
    }

    &__buttons {
      display: flex;
    }
  }

  .copyright {
    width: 100%;
    text-align: center;
    padding: $martech-spacer-4 $martech-spacer-3;
    background-color: $martech-gray-160;

    p,
    :deep(a) {
      color: $martech-white;
      letter-spacing: 0.5px;
      font-size: $martech-type-12;
      font-weight: $martech-weight-normal;
      line-height: 1.25rem; // 20px
    }

    :deep(a) {
      text-decoration: underline;

      &:hover {
        color: $martech-cfb-orange-base;
      }
    }

    &__links {
      color: $martech-text-subdued;
      font-size: $martech-type-12;
      padding-top: $martech-spacer-2;
    }
  }
}
</style>
