<template>
  <base-styles>
    <div v-if="description" class="card-description" :data-testid="componentID()">
      <div ref="text" class="text">
        <!-- eslint-disable-next-line-->
        <p v-if="vertical != 'pokemon'" v-html="desc" />
        <!-- eslint-disable-next-line-->
      <span class="pokemon-description" v-if="vertical === 'pokemon'" v-html="htmlDescription" />
      </div>
      <div v-if="description.length > truncate && collapsible" class="card-description__toggle" :class="!open ? 'overlayed' : ''">
        <button class="btn" @click="openDescription">
          <span v-if="!open">Read More <chevron direction="down"/></span>
          <span v-if="open">Read Less <chevron direction="up"/></span>
        </button>
      </div>
    </div>
    <div v-else class="alert">
      <alert
        type="warning"
        rounded-corners
        centered-text
        alert-text="Sorry, no card description was found."/>
    </div>
  </base-styles>
</template>

<script>
import BaseStyles from '@/components/BaseStyles.vue';
import Chevron from '@/components/iconography/Chevron.vue';
import manaCost from '@/lib/manaCost';
import Alert from '@/components/page-elements/Alert.vue';
import componentId from '@/mixins/componentId';

export default {
  name: 'card-description',
  components: {
    BaseStyles,
    Chevron,
    Alert,
  },
  props: {
    description: {
      type: String,
      required: true,
      default: '',
    },
    truncate: {
      type: Number,
      required: false,
      default: 400,
    },
    vertical: {
      type: String,
      required: false,
      default: 'magic',
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
    popup: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const componentID = componentId;
    return { componentID };
  },
  data() {
    return {
      open: false,
      newlines: /\n/g,
      stripTags: /<\/?[^>]+>/g,
      imageLength: 0,
    };
  },
  computed: {
    desc() {
      const desc = this.cleanDescription(this.description);
      return manaCost.getManaCostSymbols(desc);
    },
    htmlDescription() {
      const desc = this.description.replace(this.newlines, '')
      // pokemon
        .replaceAll('{C}', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/colorless.svg" alt="Colorless Energy" class="energy"/>')
        .replaceAll('[Colorless]', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/colorless.svg" alt="Colorless Energy" class="energy"/>')
        .replaceAll('{G}', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/grass.svg" alt="Grass Energy" class="energy"/>')
        .replaceAll('[Grass]', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/grass.svg" alt="Grass Energy" class="energy"/>')
        .replaceAll('{F}', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/fire.svg" alt="Fire Energy" class="energy"/>')
        .replaceAll('[Fire]', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/fire.svg" alt="Fire Energy" class="energy"/>')
        .replaceAll('{W}', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/water.svg" alt="Water Energy" class="energy"/>')
        .replaceAll('[Water]', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/water.svg" alt="Water Energy" class="energy"/>')
        .replaceAll('{L}', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/lightening.svg" alt="Lightening Energy" class="energy"/>')
        .replaceAll('[Lightening]', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/lightening.svg" alt="Lightening Energy" class="energy"/>')
        .replaceAll('{P}', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/psychic-v2.svg" alt="Psychic Energy" class="energy"/>')
        .replaceAll('[Psychic]', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/psychic-v2.svg" alt="Psychic Energy" class="energy"/>')
        .replaceAll('{FT}', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/fighting-v2.svg" alt="Fighting Energy" class="energy"/>')
        .replaceAll('[Fighting]', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/fighting-v2.svg" alt="Fighting Energy" class="energy"/>')
        .replaceAll('{D}', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/darkness-v2.svg" alt="Darkness Energy" class="energy"/>')
        .replaceAll('[Darkness]', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/darkness-v2.svg" alt="Darkness Energy" class="energy"/>')
        .replaceAll('{M}', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/metal.svg" alt="Metal Energy" class="energy"/>')
        .replaceAll('[Metal]', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/metal.svg" alt="Metal Energy" class="energy"/>')
        .replaceAll('{FY}', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/fairy.svg" alt="Fairy Energy" class="energy"/>')
        .replaceAll('[Fairy]', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/fairy.svg" alt="Fairy Energy" class="energy"/>')
        .replaceAll('{DG}', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/dragon.svg" alt="Dragon" class="energy"/>')
        .replaceAll('[Dragon]', '<img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/energy-symbols/dragon.svg" alt="Dragon" class="energy"/>')
        .trim();
      return this.cleanDescription(desc);
    },
  },
  methods: {
    cleanDescription(desc) {
      if (this.open || this.truncate < 1 || !this.collapsible || this.popup) {
        return desc;
      }

      if (desc.length <= this.truncate) {
        return desc;
      }

      const image = desc.match(/<img[\w\W]*?src="([^"]+?)"[\w\W]*?>/g);
      let imageLength = 0;

      if (image) {
        for (let i = 0; i < image.length; i++) {
          imageLength += image[i].length;
        }
      }

      // We don't want to our newlines push the page down too much, so we truncate harder to use less vertical space.
      const count = (desc.slice(0, this.truncate).match(this.newlines) || []).length;

      let trim = this.truncate;
      if (count > 0) {
        trim -= ((count / 2) * 10);
      }

      return `${desc.substring(0, trim + imageLength)}${imageLength ? '' : '...'}`;
    },
    openDescription() {
     this.open = !this.open;
     this.$emit('opened', this.open);
    }
  },
};
</script>

<style lang="scss" scoped>
.card-description {
  position: relative;
  font-weight: $martech-weight-normal;
  font-size: $martech-type-14;
  line-height: $martech-type-20;

  .description {
    padding-bottom: 0;
  }

  &__toggle {
    position: absolute;
    bottom: -30px;
    left: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 0;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    z-index: 0;
    transition: bottom 150ms ease-out;

    &:before {
      position: absolute;
      bottom: -15px;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      content: "";
      background: linear-gradient(0deg, #fff 20px, hsla(0, 0%, 100%, 0));
      z-index: -1;
    }

    &.overlayed {
      padding-top: 120px;
      bottom: 0;
    }
  }

  .text {
    overflow: hidden;
    white-space: pre-line;
  }

  :deep(.energy),
  :deep(.mana) {
    width: 12px;
    height: 12px;
    margin-right: $martech-spacer-1;
    vertical-align: middle;
    line-height: 20px;
    display: inline-block;
    margin-bottom: 2.5px;
  }

  .btn {
    background: transparent;
    color: $martech-blue;
    letter-spacing: 0.5px;
    padding: 0;
    text-align: center;
    width: 100%;
    font-size: $martech-type-12;
    text-decoration: none;
    border: 0;

    span {
      display: flex;
      align-items: center;
      font-weight: $martech-weight-semibold;
      justify-content: center;
      width: 100%;
      flex-direction: row;
    }

    .martech-chevron {
      width: 12px;
      margin-left: $martech-spacer-2;
    }

    &:hover,
    &:active {
      text-decoration: none;
    }

    &:focus {
      outline: 0;
    }
  }
}

.pokemon-description {
  font-family: $martech-display-inter;
  font-size: $martech-type-14;
  line-height: $martech-type-20;
  font-weight: $martech-weight-normal;
  color: $martech-text-primary;
  letter-spacing: 0.5px;

  :deep(p) {
    font-size: $martech-type-14;
    margin-bottom: $martech-spacer-1;
  }

  :deep(h3) {
    font-size: $martech-type-14;
    margin: $martech-spacer-3 0 $martech-spacer-1 0;

    &:first-of-type {
      margin: 0 0 $martech-spacer-1 0;
    }
  }
}

.alert {
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(768) {
    min-height: 100px;
  }
}
</style>
