<template>
  <div ref="root" class="wrapper" :data-testid="componentID()" :class="{'location-card' : card?.types?.includes('Location')}" @mouseenter="showOverlay = true" @mouseleave="showOverlay = false" @click="showOverlay = true">
    <placeholder-full-art v-if="loading" :include-text="false" width="200px" :height="placeholderHeight"/>
    <base-link v-else :link-url="cardBuyUrl" :url-target="featureFlagUrlTarget" @click="sendClickEvent">
      <transition name="fade">
        <div v-show="showOverlay" class="card-showcase-card-hover">
          <base-button btn-style="martech-black" btn-size="martech-small" class="buy-button">
            Buy on TCGplayer
          </base-button>
        </div>
      </transition>
      <img
        v-if="hasRoot"
        :src="imageURL(imageUrl, { maxDimensions: '400x500', quality: 75, })"
        :alt="card.name"
        :class="{'is-card': !isProduct}"
        class="card-image card-corners"
        @error="errorImageFallback($event)">
    </base-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import {
  BaseButton,
  BaseLink,
  ImageAlter,
  ImageHelper as image,
  LinkHelper as link,
  VerticalHelpers as verts,
  PlaceholderFullArt
} from '@tcgplayer/martech-components';

import amplitudeEvent from '@tcgplayer/amplitude';
import deviceType from '@/mixins/deviceType';
import useLinksSameTab from '@/use/featureFlags/useLinksSameTab';

export default {
  name: 'card-showcase-card',
  components: {
    BaseButton,
    BaseLink,
    PlaceholderFullArt,
  },
  mixins: [ ImageAlter, deviceType ],
  props: {
    card: {
      type: Object,
      required: true,
      default: () => ({
        image: '', name: '', tcgProductURL: '', tcgPlayerID: '', analyticsCampaign: '', analyticsSource: '',
      }),
    },
    isProduct: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isOpenLinkSameTabEnabled, featureFlagUrlTarget } = useLinksSameTab();

    return {
      isOpenLinkSameTabEnabled,
      featureFlagUrlTarget,
    };
  },
  data() {
    return {
      showOverlay: false,
    };
  },
  computed: {
    ...mapState('article', [ 'article', 'author', 'analytics' ]),
    fallbackImage() {
      return image.getFallbackImage(null, 'ghosty');
    },
    imageUrl() {
      if (this.card.tcgPlayerID) {
        return image.getCardImage(this.card.tcgPlayerID);
      }

      return this.card.image || this.fallbackImage;
    },
    cardBuyUrl() {
      return link.urlUtmBuilder(this.card.tcgProductURL, this.analytics);
    },
    placeholderHeight() {
      return this.deviceType === 'mobile' ? '178px' : '279px';
    },
  },
  methods: {
    sendClickEvent() {
      amplitudeEvent('martech', 'buyButton', {
        component: this.$options.name,
        title: this.article.title,
        type: 'card',
        name: this.card.name,
        game: verts.displayName(this.card.game),
        utm_source: this.author.analyticsSource,
        itm_source: this.author.analyticsSource,
        author: this.author.name,
        date_published: this.article.dateTime,
        format: this.article.format,
        tags: this.article.tags,
      });
    },
    errorImageFallback(event) {
      event.target.src = this.fallbackImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;

  &.location-card {
    @include breakpoint(768) {
      height: 150px;
    }

    .card-showcase-card-hover {
      @include breakpoint(768) {
        height: 150px;
      }
    }
  }

  height: 178px;
  margin: auto;

  @include breakpoint(768) {
    width: 200px;
    height: 279px;
  }
}
.card-showcase-card-hover {
  position: absolute;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.45);
  padding: $martech-spacer-3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: auto;
  width: 100%;
  height: 178px;

  @include breakpoint(768) {
    height: 280px;
    width: 200px;
  }

  &:hover {
    text-decoration: none;
  }
}

.card-image {
  position: relative;
  display: block;
  margin: auto;
  z-index: 1;
  max-height: 178px;

  .is-card {
    height: 178px;
  }

  @include breakpoint(768) {
    max-width: 200px;
    max-height: 279px;

    .is-card {
      width: 200px;
      height: 279px;
    }
  }

  &.card-corners {
    border-radius: 6px;

    @include breakpoint(1024) {
      border-radius: 10px;
    }
  }
}
</style>
