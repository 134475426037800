<template>
  <div :data-testid="componentID()">
    <delay-load @visible="onVisible">
      <div v-if="render" class="event-spotlight">
        <base-link :link-url="eventLink" class="wrapper" :new-window="!isOpenLinkSameTabEnabled" :url-target="featureFlagUrlTarget">
          <img :src="eventData.imageURL || fallbackImage" :alt="name" @error="errorImageFallback($event)">
          <div class="card-content">
            <div class="group">
              <p class="label">
                Event Spotlight
              </p>
              <div><event-star v-for="(l, i) in eventLevelStars" :key="i"/></div>
            </div>
            <div class="card-content__body">
              <h3 class="card-title martech-text-lg martech-barlow martech-text-semibold">
                {{ truncateName }}
              </h3>
              <p class="card-date martech-text-sm">
                {{ formattedDate }} <span v-if="players">| {{ players }} Players</span>
              </p>
              <div class="footer">
                <p class="martech-format-tag">
                  {{ eventData.format }}
                </p>
                <events-icon/>
              </div>
            </div>
          </div>
          <div class="background-overlay"/>
          <div class="radial-gradient"/>
        </base-link>
      </div>
    </delay-load>
    <placeholder v-if="!render" :image-height="'110px'" :placeholder-height="'160px'"/>
  </div>
</template>

<script>
import delve from 'dlv';
import Api from '@/api/api';
import DelayLoad from '@/components/DelayLoad.vue';
import EventsIcon from '@/components/iconography/EventsIcon.vue';
import EventStar from '@/components/iconography/deck-icons/EventStar.vue';
import magic from '@/lib/magic';
import Placeholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import {
  BaseLink, ImageHelper as image, VerticalHelpers as verts
} from '@tcgplayer/martech-components';
import useLinksSameTab from '@/use/featureFlags/useLinksSameTab';

export default {
  name: 'event-spotlight',
  components: {
    EventsIcon,
    EventStar,
    DelayLoad,
    Placeholder,
    BaseLink,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    truncateTitle: {
      type: Number,
      required: false,
      default: 65,
    },
  },
  setup() {
    const { isOpenLinkSameTabEnabled, featureFlagUrlTarget } = useLinksSameTab();

    return {
      isOpenLinkSameTabEnabled,
      featureFlagUrlTarget,
    };
  },
  data() {
    return {
      eventData: {},
      render: false,
    };
  },
  computed: {
    fallbackImage() {
      return image.getFallbackImage(null, 'card-fallback-image');
    },
    truncateName() {
      return this.name.length > this.truncateTitle ? `${this.name.substring(0, this.truncateTitle)}...` : this.name;
    },
    eventLevelStars() {
      return magic.getEventLevelNumber(this.level) || 0;
    },
    formattedDate() {
      return this.date.replaceAll('-', '/');
    },
    eventLink() {
      let ev = this.name;
      if (this.date) {
        ev += ` - ${this.date}`;
      }

      return `${verts.verticalLink(this.game)}/decks/event/${encodeURIComponent(ev)}`;
    },
  },
  methods: {
    async onVisible() {
      if (this.eventId) {
        await Api.getEvent(this.eventId).then((response) => {
          this.eventData = delve(response, 'data.result.event');
          this.name = delve(this.eventData, 'name', '');
          this.date = delve(this.eventData, 'eventDate', new Date().toDateString());
          this.level = delve(this.eventData, 'level', '');
          this.game = delve(this.eventData, 'game', '');
          this.players = this.eventData.players;
          this.render = true;
        });
      }
    },
    errorImageFallback(event) {
      event.target.src = this.fallbackImage;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.placeholder) {
  width: 600px;
  display: block;
  margin: auto;
}

.event-spotlight {
  display: flex;
  justify-content: center;
  color: $martech-white;

.wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    text-shadow: 1px 1px 0 $martech-black, 0 0 16px $martech-black;
    background-color: $martech-black;
    border-radius: $martech-radius-medium;
    color: $martech-white;

    &:hover {
      text-decoration: none;
    }

     @include breakpoint(768) {
      width: 600px;
      min-height: 160px;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: $martech-radius-medium;
      bottom: 0;
      object-fit: cover;
      object-position: top center;
      position: absolute;
      top: 0;
      z-index: 1;
    }

    .group {
      display: flex;
      color: $martech-white;
      justify-content: space-between;
      align-items: center;
      height: 25%;

      :deep(#star-icon) {
        width: 15px;
        height: 15px;
      }
    }

    .card-content {
      position: relative;
      z-index: 12;
      padding: $martech-spacer-3;
      height: 160px;

      .label {
        font-family: $martech-display-semi-condensed;
        font-size: $martech-type-14;
        color: $martech-white;
        letter-spacing: 0.75px;
        text-transform: uppercase;
        font-weight: $martech-weight-semibold;
        line-height: $martech-type-14;
        padding-bottom: $martech-spacer-3;
      }

      &__body {
        height: 75%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      h3 {
        margin-bottom: $martech-spacer-2;
        letter-spacing: 1px;
        text-transform: capitalize;
        color: $martech-white;
      }

      p {
        line-height: 1rem;
      }

      .card-date {
        padding-bottom: $martech-spacer-2;
        color: $martech-white;
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > svg { /* eslint-disable-line */
          height: 20px;
          color: $martech-white;
        }
      }
    }

    .background-overlay {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
      bottom: 0;
      padding: 0 10px;
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      border-radius: $martech-radius-medium;
    }

    .radial-gradient {
      background: radial-gradient(transparent, rgba(0, 0, 0, 0.5));
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 1;
      top: 0;
      border-radius: $martech-radius-medium;
    }
  }
}
</style>
