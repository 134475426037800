<template>
  <delay-load :data-testid="componentID()" @visible="onVisible">
    <div v-if="showComponent" class="video-grid" :style="{ 'grid-template-columns': `repeat(${gridColumns}, 1fr)` }">
      <!--Placeholder-->
      <template v-if="loading">
        <div v-for="(p, index) in rows" :key="index" class="grid-item placeholder">
          <full-art-placeholder :include-text="false"/>
        </div>
      </template>
      <!--Content Card-->
      <template v-else>
        <div v-for="video in videos.slice(0, rows)" :key="video.videoID" class="grid-item">
          <video-card :data="video" new-window url-target="_blank"/>
        </div>
      </template>
    </div>
  </delay-load>
</template>

<script setup>
import Api from '@/api/api';
import DelayLoad from '@/components/DelayLoad.vue';
import FullArtPlaceholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import { VideoCard } from '@tcgplayer/martech-components';
import { set } from '@vueuse/core';
import { ref } from 'vue';

const props = defineProps({
  channelId: {
    type: String,
    required: true,
  },
  gridColumns: {
    type: Number,
    required: false,
    default: 4,
  },
  rows: {
    type: Number,
    required: false,
    default: 8,
  },
  offset: {
    type: Number,
    required: false,
    default: 0,
  },
});

const videos = ref([]);
const loading = ref(true);
const showComponent = ref(true);

async function onVisible() {
  await Api.youtubeRssFeed(props.channelId, props.rows, props.offset).then((res) => {
    // Sort videos from newest published date to oldest
    set(videos, res?.data?.results || []);
    set(loading, false);
  }).catch(() => {
    set(showComponent, false);
  });
}
</script>

<style lang="scss" scoped>
.video-grid {
  display: flex;
  flex-direction: column;
  grid-gap: $martech-spacer-4;

  @include breakpoint(768) {
    display: grid;
  }

  .placeholder {
    :deep(.delay-wrapper) {
      height: 100%;
    }

    min-height: 250px;
  }

  :deep(.video-label) {
    color: $martech-white;
  }
}
</style>