<template>
  <div :data-testid="componentID()">
    <delay-load @visible="onVisible">
      <div v-if="render" class="article-spotlight" @click="sendClickEvent()">
        <base-link class="wrapper" :link-url="articleData.canonicalURL" :url-target="featureFlagUrlTarget" :new-window="!isOpenLinkSameTabEnabled">
          <img :src="articleData.imageURL || fallbackImage" :alt="articleData.title" @error="errorImageFallback($event)">
          <div class="card-content">
            <p class="label">
              Article Spotlight
            </p>
            <h3 class="card-title martech-two-lines martech-text-lg martech-barlow martech-text-semibold">
              {{ articleData.title }}
            </h3>
            <p class="teaser martech-text-md martech-two-lines">
              {{ articleData.teaser }}
            </p>
            <div class="footer">
              <div class="author">
                <author-picture-template :source="authorData.imageURL" :image-alt-name="authorData.name"/>
                <p class="card-date martech-text-sm">
                  {{ authorData.name }}
                </p>
              </div>
              <p class="card-date">
                {{ articleData.date }}
              </p>
            </div>
          </div>
          <div class="background-overlay"/>
        </base-link>
      </div>
    </delay-load>
    <placeholder v-if="!render" :image-height="'110px'" :placeholder-height="'160px'"/>
  </div>
</template>

<script>
import delve from 'dlv';
import { mapState } from 'vuex';
import amplitudeEvent from '@tcgplayer/amplitude';
import { BaseLink, ImageHelper as image } from '@tcgplayer/martech-components';
import Api from '@/api/api';
import AuthorPictureTemplate from '@/components/templates/AuthorPictureTemplate.vue';
import DelayLoad from '@/components/DelayLoad.vue';
import Placeholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import useLinksSameTab from '@/use/featureFlags/useLinksSameTab';

export default {
  name: 'article-spotlight',
  components: {
    AuthorPictureTemplate,
    BaseLink,
    DelayLoad,
    Placeholder,
  },
  props: {
    articleId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { isOpenLinkSameTabEnabled, featureFlagUrlTarget } = useLinksSameTab();

    return {
      isOpenLinkSameTabEnabled,
      featureFlagUrlTarget,
    };
  },
  data() {
    return {
      articleData: {},
      authorData: {},
      render: false,
    };
  },
  computed: {
    ...mapState('article', [ 'article' ]),
    fallbackImage() {
      return image.getFallbackImage(null, 'card-fallback-image');
    },
  },
  methods: {
    async onVisible() {
      if (this.articleId) {
        await Api.getArticleMetadata(this.articleId).then((response) => {
          this.articleData = delve(response, 'data.result.article');
          this.authorData = delve(response, 'data.result.author');
          this.render = true;
        });
      }
    },
    errorImageFallback(event) {
      event.target.src = this.fallbackImage;
    },
    sendClickEvent() {
      amplitudeEvent('martech', 'articleSpotlightClicked', {
        destinationURL: this.articleData.canonicalURL,
        spotlightTitle: this.articleData.title,
        sourceTitle: this.article.title,
        vertical: this.articleData.vertical,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.article-spotlight {
  display: flex;
  justify-content: center;

  .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: $martech-black;
    border-radius: $martech-radius-medium;

    :deep(a) {
      color: $martech-white;
      text-shadow: 1px 1px 0 $martech-black, 0 0 16px $martech-black;

      &:hover {
        text-decoration: none;
      }
    }

    @include breakpoint(768) {
      width: 600px;
      min-height: 160px;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: $martech-radius-medium;
      bottom: 0;
      object-fit: cover;
      object-position: top center;
      position: absolute;
      top: 0;
      z-index: 1;
    }

    .card-content {
      position: relative;
      z-index: 12;
      padding: $martech-spacer-3;

      .label {
        font-family: $martech-display-semi-condensed;
        font-size: $martech-type-14;
        color: $martech-white;
        letter-spacing: 0.75px;
        text-transform: uppercase;
        font-weight: $martech-weight-semibold;
        line-height: $martech-type-14;
        padding-bottom: $martech-spacer-3;
      }

      h3 {
        margin-bottom: $martech-spacer-1;
        letter-spacing: 1px;
      }

      .teaser {
        margin-bottom: $martech-spacer-2;
        font-weight: $martech-weight-normal;
      }

      .card-date {
        font-size: $martech-type-12;
        font-weight: $martech-weight-normal;
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .author {
          display: flex;
          align-items: center;

          :deep(.image__wrapper) {
            margin-right: $martech-spacer-2;
          }

          :deep(.image__item) {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
    .background-overlay {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.85)
      );
      bottom: 0;
      padding: 0 10px;
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      border-bottom-left-radius: $martech-radius-medium;
      border-bottom-right-radius: $martech-radius-medium;
    }
  }
}
</style>
